import {Component, OnInit, ViewChild} from '@angular/core';
import {Table} from 'primeng/table';
import {OffersService} from '../../../services/offers/offers-api.service';
import {LazyLoadEvent, MessageService} from 'primeng/api';
import {PageRequest} from '../../../domain/common/paging';
import {RewardProgram} from '../../../domain/offers/reward-program/reward-program';
import {Router} from '@angular/router';
import {AuthService} from "../../../services/auth.service";
import {AuthRoles} from "../../../domain/auth/auth-roles";

@Component({
  selector: 'app-page-reward-programs',
  templateUrl: './page-reward-programs.component.html',
  styleUrls: ['./page-reward-programs.component.css']
})
export class PageRewardProgramsComponent implements OnInit {

  data: RewardProgram[] = [];
  loading = true;
  cols: any[];
  formVisible = false;
  selectedItem: RewardProgram = null;
  newDisable = true;

  totalRecords: number;

  @ViewChild('dt') datatable: Table;

  constructor(private offersService: OffersService,
              private messageService: MessageService,
              private router: Router,
              private authService: AuthService) { }

  ngOnInit(): void {
    this.cols = [
      { field: 'status', header: 'Status' },
      { field: 'uuid', header: 'UUID' },
      { field: 'created', header: 'Created' },
      { field: 'title', header: 'Title' },
      { field: 'image', header: 'Image' },
    ];
    this.authService.hasRole([AuthRoles.ADMIN, AuthRoles.SUPPORT]).subscribe(result => this.newDisable = !result);
  }

  getData(pageRequest: PageRequest) {
    this.loading = true;
    this.offersService.getRewardPrograms(pageRequest).subscribe(result => {
      this.totalRecords = result.totalElements;
      this.data = result.content;
      this.loading = false;
    }, err => {
      if (!!err.status)
        this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to get reward programs.\n${err.message}`});
      this.loading = false;
    });
  }

  loadDataLazy(event: LazyLoadEvent) {
    let page: number = event.first / event.rows;
    let pageRequest: PageRequest = {page: page, size: event.rows};
    this.getData(pageRequest);
  }

  newRewardProgram() {
    this.router.navigateByUrl('offers/rewardprogram');
  }
}
