import { Injectable } from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {PageRequest, PageResponse} from '../domain/common/paging';
import {CheckoutRecord} from '../domain/checkout/checkout-records';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  getCheckoutRecords(pageRequest: PageRequest, brandId: string): Observable<PageResponse<CheckoutRecord>> {
    const params: HttpParams = new HttpParams()
      .append('merchantId', brandId)
      .append('page', `${pageRequest.page}`)
      .append('size', `${pageRequest.size}`);

    return this.http.get<PageResponse<CheckoutRecord>>(`${environment.checkoutUrl}/checkout`, {params})
      .pipe(
        map(pagedCheckoutRecords => {
          pagedCheckoutRecords.content = pagedCheckoutRecords.content.map(checkoutRecord => {
            checkoutRecord.created *= 1000;
            return checkoutRecord;
          })
          return pagedCheckoutRecords;
        }),
        catchError(
          this.handleError
        )
      );
  }
}
