import {Component, Input, OnInit} from '@angular/core';
import {WalletService} from "../../../services/wallet.service";
import {WalletAccountProperties} from "../../../domain/wallet/account-properties";
import {AuthService} from "../../../services/auth.service";
import {AuthRoles} from "../../../domain/auth/auth-roles";

@Component({
  selector: 'app-wallet-properties-card',
  templateUrl: './wallet-properties-card.component.html',
  styleUrls: ['./wallet-properties-card.component.css']
})
export class WalletPropertiesCardComponent implements OnInit {

  @Input()
  consumerId: string;

  loading = false;
  walletProperties: WalletAccountProperties = null;
  skipValidateDisable = true;

  constructor(private walletService: WalletService,
              private authService: AuthService) { }

  ngOnInit(): void {
    this.getWalletProperties();
    this.authService.hasRole([AuthRoles.ADMIN, AuthRoles.SUPPORT]).subscribe(result => this.skipValidateDisable = !result);
  }

  getWalletProperties() {
    this.loading = true;
    this.walletService.getWalletAccount(this.consumerId).subscribe(result => {
        this.loading = false;
        this.walletProperties = result.properties;

        //FIXME: remove when wallet implements defaults for account properties
        if (!this.walletProperties) {
          this.walletProperties = {};
          this.walletProperties["skipValidateScore"] = false;
        }
      }, error => {
        this.loading = false;
      });
  }

  toggleSkipValidateScore(event) {
    let checked = event.checked;
    this.loading = true;
    this.walletService.skipValidateScore(this.consumerId, checked).subscribe(() => {
      this.walletProperties.skipValidateScore = checked;
      this.loading = false;
    }, error => {
      this.loading = false;
    })
  }

}
