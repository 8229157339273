import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ConsumersApiService} from '../../../services/consumers-api.service';
import {Lead} from '../../../domain/consumers/lead';
import {ConvertedLead} from '../../../domain/consumers/converted-lead';
import {LazyLoadEvent, MenuItem} from 'primeng/api';
import {PageRequest} from '../../../domain/common/paging';
import {ErrorDisplayService} from '../../../services/error-display.service';
import {Table} from 'primeng/table';
import {FilterField} from '../../../domain/common/search/filter-field';
import {LeadStats} from '../../../domain/consumers/lead-stats';

@Component({
  selector: 'app-page-consumer-search',
  templateUrl: './page-consumer-leads.component.html',
  styleUrls: ['./page-consumer-leads.component.css']
})
export class PageConsumerLeadsComponent implements OnInit {
  @ViewChild('leadsDt') leadsDatatable: Table;
  loadingLeads = false;
  leads: Lead[] = [];
  leadsSearchForm: FormGroup;
  totalLeadsRecords = 0;
  leadsCols = [
    {field: 'id', header: 'ID'},
    {field: 'name', header: 'Name'},
    {field: 'email', header: 'Email'},
    {field: 'phoneNumber', header: 'Phone Number'},
    {field: 'brands', header: 'Brands Associated'},
    // {field: 'actions', header: ''},
  ];

  showConvertedLeadsTable = false;
  @ViewChild('convertedLeadsDt') convertedLeadsDatatable: Table;
  loadingConvertedLeads = false;
  convertedLeads: ConvertedLead[] = [];
  convertedLeadsSearchForm: FormGroup;
  totalConvertedLeadsRecords = 0;
  convertedLeadsCols = [
    {field: 'consumerId', header: 'ID'},
    {field: 'name', header: 'Name'},
    {field: 'email', header: 'Email'},
    {field: 'phoneNumber', header: 'Phone Number'},
    {field: 'convertedAt', header: 'Converted At'},
    {field: 'actions', header: ''},
  ];
  dateRanges: string[] = [
    'this week',
    'this month',
    'last week',
    'last month',
    'last quarter',
    'custom range',
  ];
  dateRangeSelected: string = this.dateRanges[0];

  loadingLeadStats = false;
  leadStats: LeadStats;

  selectedConvertedLead: ConvertedLead = null;

  constructor(private consumersApi: ConsumersApiService,
              private errorDisplayService: ErrorDisplayService,
              private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initializeForms()
    this.onDateRangeSelected({value: this.dateRangeSelected});
  }

  initializeForms() {
    this.leadsSearchForm = this.fb.group({
      givenName: [''],
      familyName: [''],
      email: [''],
      phone: ['']
    });
    this.convertedLeadsSearchForm = this.fb.group({
      dateFrom: [''],
      dateTo: [''],
    });
  }

  //
  // LEADS
  //

  onDateRangeSelected(event) {
    const dateFrom = new Date();
    const dateTo = new Date();
    switch (event.value) {
      case 'this week':
        dateFrom.setDate(dateFrom.getDate() - dateTo.getDay());
        dateFrom.setHours(0, 0, 0, 0);
        this.convertedLeadsSearchForm.patchValue({
          dateFrom: dateFrom.getTime(),
          dateTo: dateTo.getTime(),
        });
        break;
      case 'this month':
        dateFrom.setDate(1);
        dateFrom.setHours(0, 0, 0, 0);
        this.convertedLeadsSearchForm.patchValue({
          dateFrom: dateFrom.getTime(),
          dateTo: dateTo.getTime(),
        });
        break;
      case 'last week':
        dateFrom.setDate((dateFrom.getDate() - dateTo.getDay()) - 7);
        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setDate(dateTo.getDate() - (dateTo.getDay() + 1));
        dateTo.setHours(23, 59, 50, 999);
        this.convertedLeadsSearchForm.patchValue({
          dateFrom: dateFrom.getTime(),
          dateTo: dateTo.getTime(),
        });
        break;
      case 'last month':
        dateFrom.setMonth(dateFrom.getMonth() - 1);
        dateFrom.setHours(0, 0, 0, 0);
        dateFrom.setDate(1)
        dateTo.setDate(0);
        dateTo.setHours(23, 59, 50, 999);
        this.convertedLeadsSearchForm.patchValue({
          dateFrom: dateFrom.getTime(),
          dateTo: dateTo.getTime(),
        });
        break;
      case 'last quarter':
        // 0-11 months
        // 0-2 ... 3-5 ... 6-8 ... 9-11
        const currentMonth = dateFrom.getMonth();
        dateFrom.setHours(0, 0, 0, 0);
        dateTo.setHours(23, 59, 50, 999);
        if (currentMonth >= 0 && currentMonth <= 2) {
          // in first quarter
          dateFrom.setFullYear(dateFrom.getFullYear() - 1, 9, 1);
          dateTo.setFullYear(dateFrom.getFullYear() - 1, 11, 1);
          dateTo.setDate(dateTo.getDate() - 1);
        } else if (currentMonth >= 3 && currentMonth <= 5) {
          // in second quarter
          dateFrom.setFullYear(dateFrom.getFullYear(), 0, 1);
          dateTo.setFullYear(dateFrom.getFullYear(), 2, 1);
          dateTo.setDate(dateTo.getDate() - 1);
        } else if (currentMonth >= 6 && currentMonth <= 8) {
          // in third quarter
          dateFrom.setFullYear(dateFrom.getFullYear(), 3, 1);
          dateTo.setFullYear(dateFrom.getFullYear(), 5, 1);
          dateTo.setDate(dateTo.getDate() - 1);
        } else {
          // in fourth quarter
          dateFrom.setFullYear(dateFrom.getFullYear(), 6, 1);
          dateTo.setFullYear(dateFrom.getFullYear(), 8, 1);
          dateTo.setDate(dateTo.getDate() - 1);
        }
        this.convertedLeadsSearchForm.patchValue({
          dateFrom: dateFrom.getTime(),
          dateTo: dateTo.getTime(),
        });
        break;
      case 'custom range':
        this.convertedLeadsSearchForm.patchValue({
          dateFrom: '',
          dateTo: '',
        });
        break;
      default:
        // does nothing
        break;
    }
  }

  clearLeadsSearchForm() {
    this.leadsSearchForm.reset();
    this.doLeadsSearch();
  }

  doLeadsSearch() {
    this.leadsDatatable.first = 0;
    this.loadLeadsLazy(this.leadsDatatable.createLazyLoadMetadata());
    this.leadsSearchForm.markAsPristine();
  }

  getLeadsFilterFields() {
    const filterFields: FilterField[] = [];
    if (this.leadsSearchForm.value.givenName) {
      filterFields.push({parameter: 'givenName', value: this.leadsSearchForm.value.givenName});
    }
    if (this.leadsSearchForm.value.familyName) {
      filterFields.push({parameter: 'familyName', value: this.leadsSearchForm.value.familyName});
    }
    if (this.leadsSearchForm.value.email) {
      filterFields.push({parameter: 'email', value: this.leadsSearchForm.value.email});
    }
    if (this.leadsSearchForm.value.phone) {
      filterFields.push({parameter: 'phone', value: this.leadsSearchForm.value.phone});
    }
    return filterFields;
  }

  getLeads(pageRequest: PageRequest) {
    this.loadingLeads = true;
    const filterFields = this.getLeadsFilterFields()
    this.consumersApi.getLeads(pageRequest, filterFields).subscribe(result => {
      this.leads = result.content;
      this.loadingLeads = false;
      this.totalLeadsRecords = result.totalElements;
    }, error => {
      this.loadingLeads = false;
      this.errorDisplayService.displayErrorResponse('Get Leads', error);
    });
  }

  getLeadsOptions(lead: Lead) {
    // TODO
    const menuOptions: MenuItem[] = [];
    return menuOptions;
  }

  loadLeadsLazy(event: LazyLoadEvent) {
    const page: number = event.first / event.rows;
    const pageRequest: PageRequest = {page, size: event.rows};
    this.getLeads(pageRequest);
  }

  //
  // CONVERTED LEADS
  //

  clearConvertedLeadsSearchForm() {
    this.dateRangeSelected = this.dateRanges[0];
    this.onDateRangeSelected({value: this.dateRanges[0]});
    this.doConvertedLeadsSearch();
  }

  doConvertedLeadsSearch() {
    this.convertedLeadsDatatable.first = 0;
    this.loadConvertedLeadsLazy(this.convertedLeadsDatatable.createLazyLoadMetadata());
    this.convertedLeadsSearchForm.markAsPristine();
  }

  getConvertedLeadsFilterFields() {
    const filterFields: FilterField[] = [];
    if (this.convertedLeadsSearchForm.value.dateFrom) {
      filterFields.push({parameter: 'dateFrom', value: Math.floor(this.convertedLeadsSearchForm.value.dateFrom / 1000)});
    }
    if (this.convertedLeadsSearchForm.value.dateTo) {
      filterFields.push({parameter: 'dateTo', value: Math.floor(this.convertedLeadsSearchForm.value.dateTo / 1000)});
    }
    return filterFields;
  }

  getConvertedLeads(pageRequest: PageRequest) {
    this.loadingConvertedLeads = true;
    const filterFields = this.getConvertedLeadsFilterFields()
    this.consumersApi.getLeadsConversions(pageRequest, filterFields).subscribe(result => {
      this.convertedLeads = result.content;
      this.loadingConvertedLeads = false;
      this.totalConvertedLeadsRecords = result.totalElements;
    }, error => {
      this.loadingConvertedLeads = false;
      this.errorDisplayService.displayErrorResponse('Get Converted Leads', error);
    });
  }

  loadConvertedLeadsLazy(event: LazyLoadEvent) {
    this.getLeadStats();
    const page: number = event.first / event.rows;
    const pageRequest: PageRequest = {page, size: event.rows};
    this.getConvertedLeads(pageRequest);
  }

  getLeadStats() {
    this.loadingLeadStats = true;
    const filterFields = this.getConvertedLeadsFilterFields()
    this.consumersApi.getLeadsStats(filterFields).subscribe(result => {
      this.leadStats = result;
      this.loadingLeadStats = false;
    }, error => {
      this.loadingLeadStats = false;
      this.errorDisplayService.displayErrorResponse('Get Lead Stats', error);
    });
  }
}
