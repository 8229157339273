<ng-container *ngIf="!consumerId">
  <form [formGroup]="form" class="row py-2" (ngSubmit)="doSearch()" (keydown.enter)="$event.preventDefault()" novalidate>

    <div class="form-group col-xs-12 col-sm-6 col-lg-3" >
      <label class="text-uppercase" for="startDateTime">Start Date</label>
      <p-calendar class="d-block p-fluid" inputStyleClass="form-control" id="startDateTime" showTime="true" formControlName="startDateTime"  ></p-calendar>
    </div>

    <div class="form-group col-xs-12 col-sm-6 col-lg-3">
      <label class="text-uppercase" for="endDateTime">End Date</label>
      <p-calendar class="d-block p-fluid" inputStyleClass="form-control" id="endDateTime" showTime="true" formControlName="endDateTime" ></p-calendar>
    </div>

    <div class="form-group col-xs-12 col-sm-6 col-md-6">
      <label class="text-uppercase" for="userUuid">User UUID</label>
      <input class="form-control" id="userUuid" type="text" formControlName="userUuid">
    </div>

    <div class="form-group col-xs-12 col-sm-6 col-md-6">
      <label class="text-uppercase" for="authCode">Auth Code</label>
      <input class="form-control" id="authCode" type="text" formControlName="authCode">
    </div>

    <div class="form-group col-xs-12 col-sm-6 col-md-6">
      <label class="text-uppercase" for="deviceUuid">Device</label>
      <input class="form-control" id="deviceUuid" type="text" formControlName="deviceUuid">
    </div>

    <div class="form-group col-xs-12 col-sm-6 col-md-6">
      <label class="text-uppercase" for="minAmount">Min Amount</label>
      <input class="form-control" id="minAmount" type="number" formControlName="minAmount">
    </div>

    <div class="form-group col-xs-12 col-sm-6 col-md-6">
      <label class="text-uppercase" for="maxAmount">Max Amount</label>
      <input class="form-control" id="maxAmount" type="number" formControlName="maxAmount">
    </div>

    <div class="form-group col-xs-12 col-sm-6 col-lg-5">
      <label class="text-uppercase" for="merchant">Merchant</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <button (click)="toggle('merchantFilterExact')" class="btn " [ngClass]="{'btn-primary':form.controls['merchantFilterExact'].value, 'btn-outline-secondary':!form.controls['merchantFilterExact'].value}" type="button">{{form.controls['merchantFilterExact'].value ? 'Exact' : 'Contains'}}</button>
        </div>
        <input class="form-control" id="merchant" type="text" formControlName="merchant">
      </div>
    </div>

    <div class="form-group col-xs-12 col-sm-6 col-lg-4">
      <label class="text-uppercase" for="city">City</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <button (click)="toggle('cityFilterExact')" class="btn " [ngClass]="{'btn-primary':form.controls['cityFilterExact'].value, 'btn-outline-secondary':!form.controls['cityFilterExact'].value}" type="button">{{form.controls['cityFilterExact'].value ? 'Exact' : 'Contains'}}</button>
        </div>
        <input class="form-control" id="city" type="text" formControlName="city">
      </div>
    </div>

    <div class="form-group col-xs-12 col-sm-6 col-lg-3">
      <label class="text-uppercase" for="state">State</label>
      <div class="input-group">
        <div class="input-group-prepend">
          <button (click)="toggle('stateFilterExact')" class="btn " [ngClass]="{'btn-primary':form.controls['stateFilterExact'].value, 'btn-outline-secondary':!form.controls['stateFilterExact'].value}" type="button">{{form.controls['stateFilterExact'].value ? 'Exact' : 'Contains'}}</button>
        </div>
        <input class="form-control" id="state" type="text" formControlName="state">
      </div>
    </div>


    <div class="form-group col-xs-12 col-sm-8 col-md-8">
      <label class="text-uppercase" [ngClass]="{'text-muted':(form.controls['cardBins']?.value?.length)  == 0}" >Card Bin</label>
      <div class="form-control mb-3 h-auto">
        <ng-container  *ngFor="let cardBin of cardBins">
          <p-checkbox [ngClass]="{'text-muted':(form.controls['cardBins']?.value?.length) == 0}"  name="cardBins" label="{{cardBin}}" value="{{cardBin}}" [formControl]="form.controls['cardBins']">{{cardBin}}</p-checkbox><br>
        </ng-container>
      </div>

      <label class="text-uppercase">Other</label>
      <div class="form-control h-auto">

        <p-triStateCheckbox formControlName="responseSuccess" label="Success"></p-triStateCheckbox><br>
        <p-triStateCheckbox formControlName="dcvvVerified" label="DCVV Verified"></p-triStateCheckbox><br>
        <p-triStateCheckbox formControlName="tokenized" label="Tokenized"></p-triStateCheckbox>
      </div>
    </div>


    <div class="form-group col-xs-12 col-sm-4 col-md-4">
      <label class="text-uppercase" [ngClass]="{'text-muted':form.controls['messageTypes'].value.length == 0}" >Message Types</label>
      <div class="form-control mb-3 h-auto">
        <ng-container  *ngFor="let type of messageTypes">
          <p-checkbox [ngClass]="{'text-muted':form.controls['messageTypes'].value.length == 0}"  name="messageTypes" label="{{type}}" value="{{type}}" [formControl]="form.controls['messageTypes']">{{type}}</p-checkbox><br>
        </ng-container>
      </div>

      <label class="text-uppercase" [ngClass]="{'text-muted':form.controls['transactionTypes'].value.length == 0}" >Transaction Types</label>
      <div class="form-control h-auto">
        <ng-container  *ngFor="let type of transactionTypes">
          <p-checkbox [ngClass]="{'text-muted':form.controls['transactionTypes'].value.length == 0}"  name="transactionTypes" label="{{type}}" value="{{type}}" [formControl]="form.controls['transactionTypes']">{{type}}</p-checkbox><br>
        </ng-container>
      </div>
    </div>

    <div class="col-12 text-right">
      <button class="btn btn-secondary btn-sm mr-2" (click)="clearForm()">Clear</button>
      <button type="submit" class="btn btn-primary btn-sm" [disabled]="!form.valid || loading" name="searchButton" >
        <span>Search</span>
      </button>
    </div>
  </form>
</ng-container>

<div class="row">
    <p-table [value]="transactions"  [columns]="cols" [rows]="25" [paginator]="true" [pageLinks]="8" [rowsPerPageOptions]="[25,50,100]" [responsive]="true"
             [loading]="loading"   (onLazyLoad)="loadTransactionsLazy($event)" [totalRecords]="totalRecords" [lazy]="true"  [autoLayout]="true"
             class="w-100" sortField="created" [sortOrder]="-1" styleClass="p-datatable-gridlines" #dt>
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let col of cols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-transaction>
        <tr>
          <td *ngFor="let col of cols" [ngSwitch]="col.field" >
            <ng-container *ngSwitchCase="'created'">
              <span class="d-none d-md-block">{{transaction.created | date:'medium'}}</span>
              <span class="d-block d-md-none">{{transaction.created | date:'shortDate'}}</span>
            </ng-container>
            <ng-container *ngSwitchCase="'user'">
              <i (click)="showUserPreview($event,transaction.userUuid, userOverlay)" class="fa fa-sm fa-search mr-1"></i>
              <a class="d-none d-lg-inline-block" routerLink="/user/{{transaction.userUuid}}" routerLinkActive="active">{{transaction.userUuid}}</a>
              <a class="d-inline-block d-lg-none" routerLink="/user/{{transaction.userUuid}}" routerLinkActive="active">View</a>
            </ng-container>
            <ng-container *ngSwitchCase="'transactionAmount'">
              {{transaction.transactionAmount | currency:'USD'}}
            </ng-container>
            <ng-container *ngSwitchCase="'amountApproved'">
              <i *ngIf="transaction?.response && (transaction?.response?.TransactionApproval ? transaction?.response?.TransactionApproval : transaction?.response?.Success)" style="color:green" class="fa fa-check-circle-o mr-1"></i>
              <i *ngIf="!transaction?.response || (transaction?.response?.TransactionApproval ? !transaction?.response?.TransactionApproval : !transaction?.response?.Success)" style="color:red" class="fa fa-times-circle-o mr-1"></i>
              {{transaction.totalAmountApproved | currency:'USD' }}
            </ng-container>
            <ng-container *ngSwitchCase="'callbackRequestSent'">
              {{transaction.callbackRequestSent | date:'medium'}}
            </ng-container>
            <ng-container *ngSwitchCase="'callbackResponseReceived'">
              {{transaction.callbackResponseReceived | date:'medium'}}
            </ng-container>
            <ng-container *ngSwitchCase="'callbackResponseBody'">
              <pre>{{transaction.callbackResponseBody}}</pre>
            </ng-container>
            <ng-container *ngSwitchCase="'action'">
              <button type="button" pButton label="View" (click)="selectTransaction(transaction)"></button>
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{transaction[col.field]}}
            </ng-container>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr *ngIf="totalRecords != null && totalRecords > 0">
          <td [attr.colspan]="cols.length">
            <div class="text-center">{{totalRecords}} results </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="cols.length" class="text-center w-100">
            No transactions found.
          </td>
        </tr>
      </ng-template>
    </p-table>
</div>

<p-dialog #detailPanel header="Transaction Details" modal="true" [(visible)]="detailVisible">
  <app-transaction-detail [transaction]="selectedTransaction" *ngIf="selectedTransaction != null">
  </app-transaction-detail>
</p-dialog>

<p-overlayPanel #userOverlay [showCloseIcon]="true" [style]="{'width':'400px'}">
  <app-consumer-card [consumerId]="selectedUser"></app-consumer-card>
</p-overlayPanel>

