<div class="container content p-2" *ngIf="!(organization?.id || loading)">
  <div class="row">
    <div class="col-12 text-center">
      <i class="fa fa-warning"></i><br/>
      Unable to load organization.
    </div>
  </div>
</div>

<div class="container content pt-2" *ngIf="organization?.id">
  <div class="row">
    <div class="col-8 col-md-10" >
      <ng-container  *ngIf="organization?.displayName || organization?.name">
        <h3>
          <app-value-display [value]="organization?.displayName ? organization.displayName : organization.name"></app-value-display>
        </h3>
        <app-edit-in-place [value]="organization?.description" (onSaved)="updateDescription($event)"></app-edit-in-place>
      </ng-container>
      <ng-container *ngIf="!organization?.displayName && !organization?.name">
        <h3>{{organization?.id}}</h3>
      </ng-container>
    </div>
    <div class="col-4 col-md-2 text-right">
      <h4 class="text-muted">{{organization?.isParent ? "Organization" : "Brand"}}</h4>
      <button [disabled]="!isAdminOrSupport" class="btn btn-danger" (click)="confirmDelete()">Delete</button>
    </div>
    <div class="col-12 py-0 my-0">
      <hr>
    </div>
  </div>
  <div class="row"  *ngIf="loading">
    <div class="col"></div>
    <div class="text-center col">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div class="col"></div>
  </div>
  <p-tabView class="tabview" *ngIf="!!organization && !loading">
    <p-tabPanel header="Details">
      <div class="row" *ngIf="organization && !loading">
        <app-st-card-view title="Info" [loading]="loading" class="col-12 col-md-6 col-lg-6" [subtitle]="organization?.id">
          <app-st-card-view-row title="Created" class="col-12">
            <app-value-display [value]="organization?.createdAt" type="timestamp"></app-value-display>
          </app-st-card-view-row>
          <app-st-card-view-row title="Updated" class="col-12">
            <app-value-display [value]="organization?.lastUpdatedAt" type="timestamp"></app-value-display>
          </app-st-card-view-row>
          <app-st-card-view-row title="Business Type" class="col-12">
            <app-value-display [value]="organization?.businessType"></app-value-display>
          </app-st-card-view-row>
          <app-st-card-view-row title="Tax ID" class="col-12">
            <app-value-display [value]="organization.taxId"></app-value-display>
          </app-st-card-view-row>
          <app-st-card-view-row title="Display In List" class="col-12" *ngIf="!!organization && !organization?.isParent">
            <p-inputSwitch [(ngModel)]="organization.displayInList" (onChange)="handleDisplayInListChange($event)"></p-inputSwitch>
          </app-st-card-view-row>
          <app-st-card-view-row title="Parent Org" class="col-12" *ngIf="organization?.parentId">
            <a routerLink="/enterprise/organization/{{organization.parentId}}" routerLinkActive="active">View</a>
          </app-st-card-view-row>
          <app-st-card-view-row title="Affiliate" class="col-12" *ngIf="organization?.isParent">
            <app-value-display [value]="affiliate?.id"></app-value-display>
          </app-st-card-view-row>
          <app-st-card-view-row title="Invitation Url" class="col-12 py-1" *ngIf="!!organization && !organization?.isParent">
            <app-edit-in-place [value]="organization?.invitationURL" (onSaved)="updateInvitationUrl($event)">
              <ng-container class="row-outside" *ngIf="organization?.invitationURL">
                <a href="{{organization?.invitationURL}}" target="_blank">
                  <i class="fa fa-lg fa-external-link ml-1"></i>
                </a>
              </ng-container>
            </app-edit-in-place>
          </app-st-card-view-row>
        </app-st-card-view>

        <app-st-card-view title="Brand Settings" class="col-12 col-md-6 col-lg-6" *ngIf="organization?.brandSettings">
          <app-st-card-view-row class="col-12" *ngFor="let p of organization?.brandSettings" [title]="p.key">
            <app-value-display [value]="p.value"></app-value-display>
            <div *ngIf="p.value.length === 7 && p.value.startsWith('#')" [style]="{width: '10px', height: '10px', backgroundColor: p.value, display: 'inline-block'}"></div>
          </app-st-card-view-row>
        </app-st-card-view>

        <app-st-card-view title="Address" [loading]="loading"  class="col-12 col-md-6 col-lg-6">
          <app-st-card-view-row title="Address 1" class="col-12">
            <app-edit-in-place [value]="organization?.address1" (onSaved)="updateAddress1($event)"></app-edit-in-place>
          </app-st-card-view-row>

          <app-st-card-view-row title="Address 2" class="col-12">
            <app-edit-in-place [value]="organization?.address2" (onSaved)="updateAddress2($event)"></app-edit-in-place>
          </app-st-card-view-row>

          <app-st-card-view-row title="State" class="col-12">
            <app-edit-in-place [value]="organization?.state" (onSaved)="updateState($event)"></app-edit-in-place>
          </app-st-card-view-row>

          <app-st-card-view-row title="City" class="col-12">
            <app-edit-in-place [value]="organization?.city" (onSaved)="updateCity($event)"></app-edit-in-place>
          </app-st-card-view-row>

          <app-st-card-view-row title="Zip" class="col-12">
            <app-edit-in-place [value]="organization?.zip" (onSaved)="updateZip($event)"></app-edit-in-place>
          </app-st-card-view-row>
        </app-st-card-view>

        <app-st-card-view title="Brands" [loading]="loading"  class="col-12 col-md-6 col-lg-6" *ngIf="organization?.isParent" (onAddClicked)="addNewBrand()" [showAddButton]="true">
          <ng-container *ngFor="let brand of brands">
            <app-st-card-view-row [title]="brand.name" class="col-12">
              <a [routerLink]="'/enterprise/organization/' + brand.id">{{ brand.id }}</a>
            </app-st-card-view-row>
          </ng-container>
          <div class="col-12 text-muted p-text-italic" *ngIf="!brandsLoading && brands.length < 1">
            No Brands
          </div>
        </app-st-card-view>

        <app-st-card-view title="Phone Numbers" class="col-12 col-md-6 col-lg-6" [showEmpty]="!organization?.phoneNumbers || organization?.phoneNumbers.length === 0"
                          emptyMessage="No phone numbers" *ngIf="!organization?.isParent">
          <div class="st-card-subtitle text-muted">
            <button type="button" class="btn btn-primary btn-sm" (click)="showNewPhoneNumberForm()">Manage</button>
          </div>
          <app-st-card-view-row class="col-12" *ngFor="let p of organization?.phoneNumbers; let i = index;" [title]="p.description">
            {{ p.number }}
          </app-st-card-view-row>
        </app-st-card-view>

        <app-st-card-view title="Websites" class="col-12 col-md-6 col-lg-6" [showEmpty]="!organization?.websites || organization?.websites.length === 0"
                          emptyMessage="No websites" *ngIf="!organization?.isParent">
          <div class="st-card-subtitle text-muted">
            <button type="button" class="btn btn-primary btn-sm" (click)="showNewWebsiteForm()">Manage</button>
          </div>
          <app-st-card-view-row class="col-12" *ngFor="let p of organization?.websites; let i = index;" [title]="p.description">
            <a [href]="p.url">{{p.url}}</a>
          </app-st-card-view-row>
        </app-st-card-view>

        <app-st-card-view title="Magic Links" [loading]="loadingLinks" class="col-12 col-md-6 col-lg-6" [showEmpty]="organizationLinks.length === 0"
                          emptyMessage="No magic links found." *ngIf="!organization?.isParent">
          <div class="st-card-subtitle text-muted">
            <button type="button" class="btn btn-primary btn-sm" (click)="showNewOrganizationLinkForm()">Manage</button>
          </div>
          <app-st-card-view-row class="col-12" *ngFor="let p of organizationLinks; let i = index;" [title]="p.shortCode">
            <a [href]="p.url">{{p.url}}</a>
          </app-st-card-view-row>
        </app-st-card-view>

        <app-st-card-view title="Images" class="col-12 col-md-6 col-lg-6" *ngIf="!organization?.isParent"
                          [showEmpty]="!organization?.images || organization?.images.length === 0" emptyMessage="No images found. New images take time. Please refresh after a while.">
          <div class="st-card-subtitle text-muted">
            <button type="button" class="btn btn-primary btn-sm" (click)="showAddImageForm = true">Upload</button>
          </div>
          <app-st-card-view-row class="col-12" *ngFor="let p of organization?.images;let i = index;" [title]="p.fileName + ' [' + p.type + ']'">
            <img [src]="p.url" width="30px"/>
            <button (click)="deleteImage(i)" pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-text"></button>
          </app-st-card-view-row>
          <small class="col-12 text-muted p-text-italic">New images take time. Please refresh after a while.</small>
        </app-st-card-view>

        <app-st-card-view title="Business Hours" class="col-12 col-md-6 col-lg-6" [subtitle]="organization?.businessHours?.timeZone ? organization?.businessHours?.timeZone : 'Timezone missing/invalid'" *ngIf="!organization?.isParent">
          <app-st-card-view-row class="col-12" *ngFor="let p of daysOfWeek" [title]="p">
            <span class="text-muted">{{getBusinessHoursForDay(p)}}</span>
          </app-st-card-view-row>
          <button class="btn btn-primary btn-sm m-auto" (click)="showUpdateBusinessHoursForm = true">Update Business Hours</button>
        </app-st-card-view>

        <app-st-card-view title="Features" class="col-12 col-md-6 col-lg-6" [loading]="featuresLoading" *ngIf="!organization?.isParent">
          <app-st-card-view-row class="col-12" title="bizChat">
            <p-inputSwitch (onChange)="updateBizChat($event)" [(ngModel)]="bizChat"></p-inputSwitch>
          </app-st-card-view-row>
        </app-st-card-view>

        <app-st-card-view title="Legal Documents" [loading]="loadingDocuments" class="col-12 col-md-6 col-lg-6"
                          [showEmpty]="false" emptyMessage="No documents uploaded" *ngIf="!organization.isParent">
          <div class="st-card-subtitle text-muted">
            <button type="button" class="btn btn-primary btn-sm" (click)="showNewDocumentForm()">Upload</button>
          </div>
          <app-st-card-view-row class="col-12" *ngFor="let p of organizationDocuments; let i = index;" title="{{p.fileName}} ({{p.type}})">
            <a [href]="p.url" target="_blank" rel="noopener noreferrer">View</a>
          </app-st-card-view-row>
        </app-st-card-view>

        <ng-container *ngIf="!organization.isParent">
          <div class="col-12"><hr></div>
          <ng-container *ngIf="!offersMerchant">
            <div class="col-12 text-center py-1">
              <button pButton label="Create Rewards Program" (click)="createRewardProgram()"></button>
            </div>
          </ng-container>
          <ng-container *ngIf="offersMerchant">
            <app-st-card-view title="Reward Program" subtitle="Offers API" [loading]="merchantLoading"
                              class="col-12 col-md-6 col-lg-6"
                              *ngIf="rewardProgram || !(organization?.isParent)">
              <div class="col-12" *ngIf="organization && !rewardProgram && !organization?.isParent">
                <button pButton label="Create Rewards Program" (click)="createRewardProgram()"></button>
              </div>
              <app-st-card-view-row [title]="rewardProgram.title" class="col-12" *ngIf="rewardProgram">
                <a [routerLink]="'/offers/rewardprogram/' + rewardProgram?.uuid">View</a>
              </app-st-card-view-row>
            </app-st-card-view>
            <app-st-card-view title="Merchant" subtitle="Offers API" class="col-12 col-md-6 col-lg-6"
                              *ngIf="offersMerchant" [loading]="merchantLoading">
              <app-st-card-view-row class="col-12" [title]="offersMerchant?.name">
                <a [routerLink]="'/offers/merchant/' + offersMerchant.uuid">View</a>
              </app-st-card-view-row>
            </app-st-card-view>
          </ng-container>
        </ng-container>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Manage Users" *ngIf="organization?.isParent">
      <div class="row">
        <app-enterprise-users-table [organizationId]="organization?.id" class="col-12"></app-enterprise-users-table>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Subscriptions & Billing" *ngIf="!organization?.isParent">
      <div class="row">
        <app-billing-subscriptions class="col-12" [orgId]="orgId"></app-billing-subscriptions>
        <app-st-card-view title="Billing" class="col-xs-12 col-sm-6 col-md-6" [loading]="billingLoading">
          <ng-container class="st-card-subtitle" *ngIf="isAccountingOnlyRole">
            <p-button label="View Billing History" styleClass="p-button-link" (onClick)="goToBillingHistory()"></p-button>
          </ng-container>
          <app-st-card-view-row title="Last Invoice" class="col-12">
            <ng-container *ngIf="lastInvoice">
              <p-button label="{{lastInvoice?.number}}" styleClass="p-button-link" [style]="{'padding': '0'}"
                        (onClick)="showSelectedInvoice = true;"></p-button>
            </ng-container>
            <ng-container *ngIf="!lastInvoice">
              <span class="text-muted font-italic">n/a</span>
            </ng-container>
          </app-st-card-view-row>
          <app-st-card-view-row title="Last Payment" class="col-12">
            <ng-container *ngIf="lastInvoice?.status">
              <span [ngStyle]="{'color': getStatusColor(lastInvoice?.status)}">
                {{formatStatus(lastInvoice?.status)}}
              </span>
            </ng-container>
            <ng-container *ngIf="!lastInvoice?.status">
              <span class="text-muted font-italic">n/a</span>
            </ng-container>
          </app-st-card-view-row>
          <app-st-card-view-row title="Last Payment Date" class="col-12">
            <ng-container *ngIf="lastInvoice?.paymentRecord?.timestampProcessed">
              {{lastInvoice.paymentRecord.timestampProcessed | date:'mediumDate'}}
            </ng-container>
            <ng-container *ngIf="!lastInvoice?.paymentRecord?.timestampProcessed">
              <span class="text-muted font-italic">n/a</span>
            </ng-container>
          </app-st-card-view-row>
          <app-st-card-view-row title="Next Payment Date" class="col-12">
            <ng-container *ngIf="lastInvoice?.paymentRecord?.timestampProcessed">
              {{ lastInvoice.nextPaymentDate | date:'mediumDate'}}
            </ng-container>
            <ng-container *ngIf="!lastInvoice?.paymentRecord?.timestampProcessed">
              <span class="text-muted font-italic">n/a</span>
            </ng-container>
          </app-st-card-view-row>
          <app-st-card-view-row title="Payment Method" class="col-12">
            <ng-container *ngIf="lastInvoice?.paymentRecord?.paymentMethod">
              {{lastInvoice?.paymentRecord?.paymentMethod}}
            </ng-container>
            <ng-container *ngIf="!lastInvoice?.paymentRecord?.paymentMethod">
              <span class="text-muted font-italic">n/a</span>
            </ng-container>
          </app-st-card-view-row>
          <app-st-card-view-row title="Payment Details (last digits)" class="col-12">
            <ng-container *ngIf="lastInvoice?.paymentRecord?.paymentInstrumentNumber">
              {{lastInvoice?.paymentRecord?.paymentInstrumentNumber}}
            </ng-container>
            <ng-container *ngIf="!lastInvoice?.paymentRecord?.paymentInstrumentNumber">
              <span class="text-muted font-italic">n/a</span>
            </ng-container>
          </app-st-card-view-row>
          <app-st-card-view-row title="Payment Status" class="col-12">
            <ng-container *ngIf="lastInvoice?.paymentRecord?.status">
              <span [ngStyle]="{'color': BillingRecordStatus.getStatusColor(lastInvoice?.paymentRecord?.status)}">
                {{BillingRecordStatus.formatStatusName(lastInvoice?.paymentRecord?.status)}}
              </span>
            </ng-container>
            <ng-container *ngIf="!lastInvoice?.paymentRecord?.status">
              <span class="text-muted font-italic">n/a</span>
            </ng-container>
          </app-st-card-view-row>
        </app-st-card-view>
        <app-st-card-view title="Payment Instrument" class="col-xs-12 col-sm-6 col-md-6"
                          *ngIf="organization?.id && !organization?.isParent" [loading]="loading">
          <app-st-card-view-row title="No payment instruments" class="col-12"
                                *ngIf="(!hasActiveCards && !hasActiveBanks) || isInstrumentDeleted">
            <button class="btn btn-primary btn-xl mt-1 mb-1" (click)="newPaymentInstrument()">+ Add</button>
          </app-st-card-view-row>

          <ng-container *ngIf="hasActiveBanks && !isInstrumentDeleted">
            <app-st-card-view-row title="Account Id" class="col-12">
              {{ activeInstrument.id }}
            </app-st-card-view-row>
            <app-st-card-view-row title="Account Name" class="col-12">
              {{ activeInstrument.accountName }}
            </app-st-card-view-row>
            <app-st-card-view-row *ngIf="activeInstrument?.bankName" title="Bank Name" class="col-12">
              {{ activeInstrument?.bankName }}
            </app-st-card-view-row>
            <app-st-card-view-row title="Account Number (Last 4 Digits)" class="col-12">
              {{ activeInstrument.lastDigits }}
            </app-st-card-view-row>
            <app-st-card-view-row title="Tender Type" class="col-12">
              {{ activeInstrument.tenderType }}
            </app-st-card-view-row>
          </ng-container>

          <ng-container *ngIf="hasActiveCards && !isInstrumentDeleted">
            <app-st-card-view-row title="Account Id" class="col-12">
              {{ activeInstrument.id }}
            </app-st-card-view-row>
            <app-st-card-view-row title="Name on Card" class="col-12">
              {{ activeInstrument.nameOnCard }}
            </app-st-card-view-row>
            <app-st-card-view-row title="Card Brand" class="col-12">
              {{ activeInstrument.cardBrand }}
            </app-st-card-view-row>
            <app-st-card-view-row title="Expiration date" class="col-12">
              {{ activeInstrument.expMonth }}/{{ activeInstrument.expYear }}
            </app-st-card-view-row>
            <app-st-card-view-row title="Card Number (Last 4 Digits)" class="col-12">
              {{ activeInstrument.lastDigits }}
            </app-st-card-view-row>
            <app-st-card-view-row title="Tender Type" class="col-12">
              {{ activeInstrument.tenderType }}
            </app-st-card-view-row>
            <app-st-card-view-row title="Address" class="col-12">
              <app-address-basic
                *ngIf="activeInstrument?.billingAddress?.address1"
                [address1]="activeInstrument.billingAddress.address1"
                [address2]="activeInstrument.billingAddress.address2"
                [city]="activeInstrument.billingAddress.city"
                [state]="activeInstrument.billingAddress.state"
                [zip]="activeInstrument.billingAddress.zip"
                class="text-right"
              ></app-address-basic>
            </app-st-card-view-row>
          </ng-container>

          <app-st-card-view-row class="col-12 mt-4" *ngIf="(hasActiveBanks || hasActiveCards) && !isInstrumentDeleted">
            <button class="btn btn-danger btn-xl mt-1 mb-1" (click)="deleteActiveInstrument()">Delete</button>
            <button class="btn btn-primary btn-xl mt-1 mb-1" (click)="newPaymentInstrument()">Edit</button>
            <button *ngIf="isAccountingOnlyRole" [disabled]="merchantInstrumentDataTimer" class="btn btn-primary btn-xl mt-1 mb-1" (click)="getMerchantInstrumentData()">
              <i class="fa fa-spinner fa-spin" *ngIf="paymentInstrumentDataLoading"></i>
              <span *ngIf="!isLoading">Show</span>
            </button>
          </app-st-card-view-row>
        </app-st-card-view>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Manage Chat Support" *ngIf="!organization?.isParent">
      <div class="row">
        <app-chat-settings *ngIf="!organization?.isParent && organization?.id" class="col-12"
                           [organization]="organization" [organizationFeatures]="organizationFeatures"></app-chat-settings>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Marketplace" *ngIf="!organization?.isParent">
      <p-tabView>
        <p-tabPanel header="Settings">
          <div class="row">
            <div class="col-12 d-flex justify-content-end mb-2">
              <p-button [disabled]="!isAdminOrSupport" label="Add Platform" icon="pi pi-plus" class="p-fluid" styleClass="p-button-primary p-button-sm" (onClick)="showAddPlatformForm = true"></p-button>
            </div>
            <div class="col-12">
              <p-table [value]="platforms"  [columns]="platformsCols" [totalRecords]="platformsTotalRecords"
                       [autoLayout]="true" [loading]="platformsLoading" styleClass="p-datatable-gridlines" #platformsDt>
                <ng-template pTemplate="header">
                  <tr>
                    <th *ngFor="let col of platformsCols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-platform>
                  <tr>
                    <td *ngFor="let col of platformsCols" [ngSwitch]="col.field">
                      <ng-container *ngSwitchCase="'platform'">
                        {{getPlatformName(platform.platform)}}
                      </ng-container>
                      <ng-container *ngSwitchCase="'url'">
                        {{platform.url}}
                        <a href="{{platform.url}}" target="_blank">
                          <i class="fa fa-lg fa-external-link ml-1"></i>
                        </a>
                      </ng-container>
                      <ng-container *ngSwitchCase="'actions'">
                        <button type="button" pButton pRipple icon="pi pi-ellipsis-v" [disabled]="!isAdminOrSupport" class="p-button-rounded p-button-text" (click)="platformMenu.toggle($event)"></button>
                        <p-menu #platformMenu [popup]="true" [model]="getPlatformsOptions(platform)" appendTo="body"></p-menu>
                      </ng-container>
                      <ng-container *ngSwitchDefault>
                        <app-value-display [value]="platform[col.field]"></app-value-display>
                      </ng-container>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                  <tr *ngIf="platformsTotalRecords != null && platformsTotalRecords !== 0">
                    <td [attr.colspan]="platformsCols.length">
                      <div class="text-center" *ngIf="platformsTotalRecords != null"> {{platformsTotalRecords}} results </div>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td [attr.colspan]="platformsCols.length" class="text-center w-100">
                      No platforms found.
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="Transactions">
          <div class="row">
            <div class="col-12 d-flex justify-content-end mb-2">
              <p-button label="Refresh" icon="pi pi-refresh" class="p-fluid" styleClass="p-button-primary p-button-sm"
                        (onClick)="refreshTransactions()"></p-button>
            </div>
            <div class="col-12">
              <p-table [value]="transactions" [columns]="transactionsCols" [rows]="25" [paginator]="true" [pageLinks]="8" [rowsPerPageOptions]="[25,50,100]"
                       [autoLayout]="true" sortField="created" [sortOrder]="-1" [loading]="transactionsLoading" [totalRecords]="transactionsTotalRecords"
                       [lazy]="true" (onLazyLoad)="loadTransactionsDataLazy($event)" styleClass="p-datatable-gridlines" #transactionsDt>
                <ng-template pTemplate="header">
                  <tr>
                    <th *ngFor="let col of transactionsCols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-transaction>
                  <tr>
                    <td *ngFor="let col of transactionsCols" [ngSwitch]="col.field" >
                      <ng-container *ngSwitchCase="'grandTotal'">
                        <app-value-display [value]="transaction.grandTotal" type="currency"></app-value-display>
                      </ng-container>
                      <ng-container *ngSwitchCase="'created'">
                        <app-value-display [value]="transaction.created" type="timestamp"></app-value-display>
                      </ng-container>
                      <ng-container *ngSwitchCase="'action'">
                        <button type="button" pButton pRipple icon="pi pi-ellipsis-v" class="p-button-rounded p-button-text" (click)="menu.toggle($event)"></button>
                        <p-menu #menu [popup]="true" [model]="getTransactionOptions(transaction)" appendTo="body"></p-menu>
                      </ng-container>
                      <ng-container *ngSwitchDefault>
                        <app-value-display [value]="transaction[col.field]"></app-value-display>
                      </ng-container>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                  <tr *ngIf="transactionsTotalRecords != null && transactionsTotalRecords > 0">
                    <td [attr.colspan]="transactionsCols.length">
                      <div class="text-center"> {{transactionsTotalRecords}} results </div>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td [attr.colspan]="transactionsCols.length" class="text-center w-100">
                      No transactions found.
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </p-tabPanel>
      </p-tabView>
    </p-tabPanel>
  </p-tabView>
</div>

<p-dialog modal="modal" header="Update Business Hours" [draggable]="false" [resizable]="false" [(visible)]="showUpdateBusinessHoursForm" [dismissableMask]="true" [style]="{width: '800px'}" (onHide)="initializeForm()">
  <form *ngIf="updateBusinessHoursForm" [formGroup]="updateBusinessHoursForm" (ngSubmit)="updateBusinessHours()" (keydown.enter)="$event.preventDefault()" novalidate>
    <div class="form-group col-12 d-flex flex-row align-items-center">
      TimeZone
      <div class="ml-auto d-flex">
        <p-autoComplete class="d-block m-auto p-fluid" inputStyleClass="form-control" id="timezone" name="timezone" [dropdown]="true"
                        formControlName="timezone" [suggestions]="timezones" (completeMethod)="searchTimezones($event)"></p-autoComplete>
      </div>
    </div>
    <hr>
    <div class="form-group col-12 d-flex flex-row align-items-center">
      Monday
      <div class="ml-auto d-flex align-items-center">
        <p-inputSwitch (onChange)="handleDayClosure($event, 'monday')"
                       [(ngModel)]="!mondayClosure" [ngModelOptions]="{standalone: true}" class="mr-2"></p-inputSwitch>
        <label class="text-uppercase m-auto" for="mondayOpensAt">Opening</label>
        <p-inputMask [disabled]="mondayClosure" class="ml-1 mr-3"  [style]="{width: '150px'}" id="mondayOpensAt" mask="99:99" formControlName="mondayOpensAt"></p-inputMask>
        <label class="text-uppercase m-auto" for="mondayClosesAt">Closing</label>
        <p-inputMask [disabled]="mondayClosure" class="ml-1"  [style]="{width: '150px'}" id="mondayClosesAt" mask="99:99" formControlName="mondayClosesAt"></p-inputMask>
      </div>
    </div>
    <hr>
    <div class="form-group col-12 d-flex flex-row align-items-center">
      Tuesday
      <div class="ml-auto d-flex">
        <p-inputSwitch (onChange)="handleDayClosure($event, 'tuesday')"
                       [(ngModel)]="!tuesdayClosure" [ngModelOptions]="{standalone: true}" class="mr-2"></p-inputSwitch>
        <label class="text-uppercase m-auto" for="tuesdayOpensAt">Opening</label>
        <p-inputMask [disabled]="tuesdayClosure" class="ml-1 mr-3"  [style]="{width: '150px'}" id="tuesdayOpensAt" mask="99:99" formControlName="tuesdayOpensAt"></p-inputMask>
        <label class="text-uppercase m-auto" for="tuesdayClosesAt">Closing</label>
        <p-inputMask [disabled]="tuesdayClosure" class="ml-1"  [style]="{width: '150px'}" id="tuesdayClosesAt" mask="99:99" formControlName="tuesdayClosesAt"></p-inputMask>
      </div>
    </div>
    <hr>
    <div class="form-group col-12 d-flex flex-row align-items-center">
      Wednesday
      <div class="ml-auto d-flex">
        <p-inputSwitch (onChange)="handleDayClosure($event, 'wednesday')"
                       [(ngModel)]="!wednesdayClosure" [ngModelOptions]="{standalone: true}" class="mr-2"></p-inputSwitch>
        <label class="text-uppercase m-auto" for="wednesdayOpensAt">Opening</label>
        <p-inputMask [disabled]="wednesdayClosure" class="ml-1 mr-3"  [style]="{width: '150px'}" id="wednesdayOpensAt" mask="99:99" formControlName="wednesdayOpensAt"></p-inputMask>
        <label class="text-uppercase m-auto" for="wednesdayClosesAt">Closing</label>
        <p-inputMask [disabled]="wednesdayClosure" class="ml-1"  [style]="{width: '150px'}" id="wednesdayClosesAt" mask="99:99" formControlName="wednesdayClosesAt"></p-inputMask>
      </div>
    </div>
    <hr>
    <div class="form-group col-12 d-flex flex-row align-items-center">
      Thursday
      <div class="ml-auto d-flex">
        <p-inputSwitch (onChange)="handleDayClosure($event, 'thursday')"
                       [(ngModel)]="!thursdayClosure" [ngModelOptions]="{standalone: true}" class="mr-2"></p-inputSwitch>
        <label class="text-uppercase m-auto" for="thursdayOpensAt">Opening</label>
        <p-inputMask [disabled]="thursdayClosure" class="ml-1 mr-3"  [style]="{width: '150px'}" id="thursdayOpensAt" mask="99:99" formControlName="thursdayOpensAt"></p-inputMask>
        <label class="text-uppercase m-auto" for="thursdayClosesAt">Closing</label>
        <p-inputMask [disabled]="thursdayClosure" class="ml-1"  [style]="{width: '150px'}" id="thursdayClosesAt" mask="99:99" formControlName="thursdayClosesAt"></p-inputMask>
      </div>
    </div>
    <hr>
    <div class="form-group col-12 d-flex flex-row align-items-center">
      Friday
      <div class="ml-auto d-flex">
        <p-inputSwitch (onChange)="handleDayClosure($event, 'friday')" class="mr-2"
                       [(ngModel)]="!fridayClosure" [ngModelOptions]="{standalone: true}" class="mr-2"></p-inputSwitch>
        <label class="text-uppercase m-auto" for="fridayOpensAt">Opening</label>
        <p-inputMask [disabled]="fridayClosure" class="ml-1 mr-3"  [style]="{width: '150px'}" id="fridayOpensAt" mask="99:99" formControlName="fridayOpensAt"></p-inputMask>
        <label class="text-uppercase m-auto" for="fridayClosesAt">Closing</label>
        <p-inputMask [disabled]="fridayClosure" class="ml-1"  [style]="{width: '150px'}" id="fridayClosesAt" mask="99:99" formControlName="fridayClosesAt"></p-inputMask>
      </div>
    </div>
    <hr>
    <div class="form-group col-12 d-flex flex-row align-items-center">
      Saturday
      <div class="ml-auto d-flex">
        <p-inputSwitch (onChange)="handleDayClosure($event, 'saturday')"
                       [(ngModel)]="!saturdayClosure" [ngModelOptions]="{standalone: true}" class="mr-2"></p-inputSwitch>
        <label class="text-uppercase m-auto" for="saturdayOpensAt">Opening</label>
        <p-inputMask [disabled]="saturdayClosure" class="ml-1 mr-3"  [style]="{width: '150px'}" id="saturdayOpensAt" mask="99:99" formControlName="saturdayOpensAt"></p-inputMask>
        <label class="text-uppercase m-auto" for="saturdayClosesAt">Closing</label>
        <p-inputMask [disabled]="saturdayClosure" class="ml-1"  [style]="{width: '150px'}" id="saturdayClosesAt" mask="99:99" formControlName="saturdayClosesAt"></p-inputMask>
      </div>
    </div>
    <hr>
    <div class="form-group col-12 d-flex flex-row align-items-center">
      Sunday
      <div class="ml-auto d-flex">
        <p-inputSwitch (onChange)="handleDayClosure($event, 'sunday')"
                       [(ngModel)]="!sundayClosure" [ngModelOptions]="{standalone: true}" class="mr-2"></p-inputSwitch>
        <label class="text-uppercase m-auto" for="sundayOpensAt">Opening</label>
        <p-inputMask [disabled]="sundayClosure" class="ml-1 mr-3"  [style]="{width: '150px'}" id="sundayOpensAt" mask="99:99" formControlName="sundayOpensAt"></p-inputMask>
        <label class="text-uppercase m-auto" for="sundayClosesAt">Closing</label>
        <p-inputMask [disabled]="sundayClosure" class="ml-1"  [style]="{width: '150px'}" id="sundayClosesAt" mask="99:99" formControlName="sundayClosesAt"></p-inputMask>
      </div>
    </div>
    <div class="float-right">
      <button class="mr-1" type="button" pButton icon="pi pi-trash" (click)="showUpdateBusinessHoursForm = false; updateBusinessHoursForm.reset();" label="Cancel"></button>
      <button [disabled]="validateUpdateBusinessHoursForm()" type="submit" pButton icon="pi pi-check" label="Submit"></button>
    </div>
  </form>
</p-dialog>

<p-dialog modal="modal" header="Payment Instrument" [draggable]="false" [resizable]="false" [(visible)]="showPaymentInstrumentData" [dismissableMask]="true" [style]="{width: '500px'}">
  <app-st-card-view
    *ngIf="organization?.id && activeInstrument?.id && !organization?.isParent"
    [loading]="paymentInstrumentDataLoading"
    [title]="paymentInstrumentDialogTitle"
    class="col-12 col-md-6 col-lg-6"
  >
    <ng-container *ngIf="paymentInstrumentsData?.bankAccountData && merchantInstrumentDataTimer">
      <app-st-card-view-row title="Account Name" class="col-12">
        {{ paymentInstrumentsData.bankAccountData.accountName }}
      </app-st-card-view-row>
      <app-st-card-view-row title="Account Number" class="col-12">
        {{ paymentInstrumentsData.bankAccountData.accountNumber }}
      </app-st-card-view-row>
      <app-st-card-view-row title="Routing Number" class="col-12">
        {{ paymentInstrumentsData.bankAccountData.routingNumber }}
      </app-st-card-view-row>
    </ng-container>

    <ng-container *ngIf="paymentInstrumentsData?.paymentCardData && merchantInstrumentDataTimer">
      <app-st-card-view-row title="Card Number" class="col-12">
        {{ paymentInstrumentsData.paymentCardData.pan }}
      </app-st-card-view-row>
      <app-st-card-view-row title="Name on Card" class="col-12">
        {{ paymentInstrumentsData.paymentCardData.nameOnCard }}
      </app-st-card-view-row>
      <app-st-card-view-row title="Expiration Date" class="col-12">
        {{ paymentInstrumentsData.paymentCardData.expMonth }}/{{ paymentInstrumentsData.paymentCardData.expYear }}
      </app-st-card-view-row>
    </ng-container>
  </app-st-card-view>
</p-dialog>

<p-dialog modal="modal" header="Manage Phone Numbers" [draggable]="false" [resizable]="false" [(visible)]="showAddPhoneNumberForm" [dismissableMask]="true"
          [style]="{width: '900px'}">
  <form [formGroup]="newPhoneNumberForm" class="p-1" (ngSubmit)="savePhoneNumber()" (keydown.enter)="$event.preventDefault()" novalidate>
    <ng-container formArrayName="phoneNumbers">
      <ng-container *ngFor="let phoneNumberFormGroup of phoneNumbers?.controls; let i = index">
        <div class="col-12 d-flex align-items-center" [formGroup]="phoneNumberFormGroup">
          <div class="form-group col-4">
            <label class="text-uppercase m-auto" for="number">Number *</label>
            <input autocomplete="off" class="form-control" id="number" type="tel" formControlName="number" maxlength="10">
          </div>
          <div class="form-group col-6">
            <label class="text-uppercase m-auto" for="phoneDescription">Description *</label>
            <input autocomplete="off" class="form-control" id="phoneDescription" type="text" formControlName="description">
          </div>
          <div class="form-group col-2">
            <button (click)="deletePhoneNumber(i)" pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-text"></button>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <div class="col-12 d-flex justify-content-center">
      <button type="button" class="btn btn-primary" (click)="addFormPhoneNumber()">Add</button>
    </div>
    <div class="text-left col">
      <button type="submit" class="btn btn-primary btn-custom" [disabled]="!this.newPhoneNumberForm.valid || !this.newPhoneNumberForm.dirty" name="saveButton">
        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
        <span *ngIf="!loading">Save</span>
      </button>
    </div>
  </form>
</p-dialog>

<p-dialog modal="modal" header="Manage Websites" [draggable]="false" [resizable]="false" [(visible)]="showAddWebsiteForm" [dismissableMask]="true"
          [style]="{width: '900px'}">
  <form [formGroup]="newWebsiteForm" class="p-1" (ngSubmit)="saveWebsite()" (keydown.enter)="$event.preventDefault()" novalidate>
    <ng-container formArrayName="websites">
      <ng-container *ngFor="let websitesFormGroup of websites?.controls; let i = index">
        <div class="col-12 d-flex align-items-center" [formGroup]="websitesFormGroup">
          <div class="col-11">
            <div class="d-flex">
              <div class="form-group col-6">
                <label class="text-uppercase m-auto" for="type">Type *</label>
                <p-dropdown appendTo="body" class="p-fluid" styleClass="form-control p-0" [autoDisplayFirst]="true"
                            [options]="websiteTypes" formControlName="type" id="type"></p-dropdown>
              </div>
              <div class="form-group col-6">
                <label class="text-uppercase m-auto" for="url">Url *</label>
                <input autocomplete="off" class="form-control" id="url" type="text" formControlName="url" aria-describedby="urlHelp">
                <small  class="form-text text-muted" id="urlHelp">Please include protocol (i.e. https://, http://)</small>
              </div>
            </div>
            <div class="form-group col-12">
              <label class="text-uppercase m-auto" for="websiteDescription">Description *</label>
              <input autocomplete="off" class="form-control" id="websiteDescription" type="text" formControlName="description">
            </div>
          </div>
          <div class="col-1 d-flex justify-content-center">
            <button (click)="deleteWebsite(i)" pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-text"></button>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <div class="col-12 d-flex justify-content-center">
      <button type="button" class="btn btn-primary" (click)="addFormWebsite()">Add</button>
    </div>
    <div class="text-left col">
      <button type="submit" class="btn btn-primary btn-custom" [disabled]="!this.newWebsiteForm.valid || !this.newWebsiteForm.dirty">
        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
        <span *ngIf="!loading">Save</span>
      </button>
    </div>
  </form>
</p-dialog>

<app-image-upload-dialog [domain]="'enterprise'" [orgId]="orgId" [visible]="showAddImageForm" (imageUploaded)="handleImageUploaded()"
                         (cancelled)="showAddImageForm = false"></app-image-upload-dialog>

<p-dialog class="w-auto" modal="modal" header="Invoice Preview" *ngIf="!organization?.isParent" [draggable]="false" [resizable]="false" [(visible)]="showSelectedInvoice" [dismissableMask]="true">
  <app-platform-billing-invoice-preview (downloadComplete)="showSelectedInvoice = false;"
                                        [selectedInvoice]="lastInvoice"
                                        [downloadFunction]="downloadInvoice"></app-platform-billing-invoice-preview>
</p-dialog>

<p-dialog class="w-auto" modal="modal" header="Downloading" [draggable]="false" [resizable]="false" [(visible)]="downloadingInvoice"
          [dismissableMask]="false" [style]="{width: '300px'}" closable="false">
  <div class="col"></div>
  <div class="text-center col">
    <p-progressSpinner></p-progressSpinner>
  </div>
  <div class="col"></div>
</p-dialog>

<p-dialog modal="modal" header="Manage Links" [draggable]="false" [resizable]="false" [(visible)]="showAddOrganizationLinkForm" [dismissableMask]="true"
          [style]="{width: '900px'}">
  <form [formGroup]="newOrganizationLinkForm" class="p-1" (ngSubmit)="saveLink()" (keydown.enter)="$event.preventDefault()" novalidate>
    <ng-container formArrayName="links">
      <ng-container *ngFor="let linksFormGroup of links?.controls; let i = index">
        <div class="col-12 d-flex align-items-center" [formGroup]="linksFormGroup">
          <div class="col-11 d-flex">
            <div class="form-group col-4">
              <label class="text-uppercase m-auto" for="shortCode{{i}}">Keyword *</label>
              <input autocomplete="off" class="form-control" id="shortCode{{i}}" type="text" formControlName="shortCode">
            </div>
            <div class="form-group col-8">
              <label class="text-uppercase m-auto" for="url{{i}}">Url *</label>
              <input autocomplete="off" class="form-control" id="url{{i}}" type="text" formControlName="url" aria-describedby="urlHelp">
              <small  class="form-text text-muted" id="urlHelp{{i}}">Please include protocol (i.e. https://, http://)</small>
            </div>
          </div>
          <div class="col-1 d-flex justify-content-center">
            <button (click)="deleteLink(i)" pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-text"></button>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <div class="col-12 d-flex justify-content-center">
      <button type="button" class="btn btn-primary" (click)="addFormLink()">Add</button>
    </div>
    <div class="text-left col">
      <button type="submit" class="btn btn-primary btn-custom" [disabled]="!this.newOrganizationLinkForm.valid || !this.newOrganizationLinkForm.dirty">
        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
        <span *ngIf="!loading">Save</span>
      </button>
    </div>
  </form>
</p-dialog>

<app-file-upload-dialog header="Upload New Merchant File" [visible]="showLegalDocumentUpload" [orgId]="orgId"
                        (uploaded)="handleDocumentUpload()" (cancelled)="showLegalDocumentUpload = false"></app-file-upload-dialog>

<p-dialog modal="modal" header="Add Platform" [draggable]="false" [resizable]="false" [(visible)]="showAddPlatformForm" [dismissableMask]="true"
          [style]="{width: '600px'}" (onHide)="clearNewPlatform()">
  <form [formGroup]="newPlatformForm" class="p-1" (keydown.enter)="$event.preventDefault()" novalidate>
    <div class="row">
      <label class="col-12 form-group">
        <span class="text-uppercase">Platform *</span>
        <p-dropdown appendTo="body" class="p-fluid" styleClass="form-control p-0" [options]="newPlatformOptions"
                    formControlName="platform" [autoDisplayFirst]="false"></p-dropdown>
      </label>
    </div>
    <div class="row">
      <label class="col-12 form-group">
        <span class="text-uppercase">Url *</span>
        <input autocomplete="off" class="form-control" formControlName="url">
      </label>
    </div>
    <div class="row">
      <label class="col-12 form-group">
        <span class="text-uppercase">Key ID *</span>
        <input autocomplete="off" class="form-control" formControlName="keyId">
      </label>
    </div>
    <div class="row">
      <label class="col-12 form-group">
        <span class="text-uppercase">Consumer Key *</span>
        <input autocomplete="off" class="form-control" formControlName="consumerKey">
      </label>
    </div>
    <div class="row">
      <label class="col-12 form-group">
        <span class="text-uppercase">Consumer Secret *</span>
        <input autocomplete="off" class="form-control" formControlName="consumerSecret">
      </label>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <p-button label="Save" styleClass="p-button-sm"
              [disabled]="!newPlatformForm.valid || !newPlatformForm.dirty"
              (onClick)="handleAddPlatformFormSubmit()"></p-button>
  </ng-template>
</p-dialog>

<p-dialog modal="modal" header="Edit Store" [draggable]="false" [resizable]="false" [(visible)]="showEditPlatformStoreForm" [dismissableMask]="true"
          [style]="{width: '600px'}" (onHide)="clearEditPlatformStore()">
  <form [formGroup]="editPlatformStoreForm" class="p-1" (keydown.enter)="$event.preventDefault()" novalidate>
    <div class="row">
      <label class="col-12 form-group">
        <span class="text-uppercase">Platform</span>
        <input autocomplete="off" class="form-control" formControlName="platform" disabled>
      </label>
    </div>
    <div class="row">
      <label class="col-12 form-group">
        <span class="text-uppercase">Alias *</span>
        <input autocomplete="off" class="form-control" formControlName="alias">
      </label>
    </div>
    <div class="row">
      <label class="col-12 form-group">
        <span class="text-uppercase">Url</span>
        <input autocomplete="off" class="form-control" formControlName="url" disabled>
      </label>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <p-button label="Save" styleClass="p-button-sm"
              [disabled]="!editPlatformStoreForm.valid || !editPlatformStoreForm.dirty"
              (onClick)="handleEditPlatformStoreFormSubmit()"></p-button>
  </ng-template>
</p-dialog>

<p-dialog modal="modal" header="Transaction Details" [draggable]="false" [resizable]="false" [(visible)]="showSelectedTransaction" [dismissableMask]="true"
          [style]="{width: '900px'}" *ngIf="selectedTransaction">
  <div>
    Transaction ID - <span class="text-muted">TRANSACTION ID</span>
  </div>
  <div class="d-flex flex-column" style="gap: 1rem;">
    <div class="content col-12">
      <div class="col-12 d-flex py-2 px-0">
        <div class="d-flex flex-column col-12 p-0">
          <div class="font-weight-bold">Description</div>
          <app-value-display [value]="selectedTransaction.description"></app-value-display>
        </div>
      </div>
      <div class="col-12 d-flex py-2 px-0">
        <div class="d-flex flex-column col-4 p-0">
          <div class="font-weight-bold">Payment Method</div>
          <app-value-display [value]=""></app-value-display>
        </div>
        <div class="d-flex flex-column col-4 p-0 align-items-center"><!-- space filler --></div>
        <div class="d-flex flex-column col-4 p-0 align-items-end">
          <div class="font-weight-bold">Payment Date</div>
          <app-value-display [value]="selectedTransaction.paymentResponse?.Received" type="timestamp"></app-value-display>
        </div>
      </div>
      <div class="col-12 d-flex py-2 px-0">
        <div class="d-flex flex-column col-12 p-0">
          <div class="font-weight-bold">Payment Status</div>
          <app-value-display [value]="selectedTransaction.paymentResult"></app-value-display>
        </div>
      </div>
    </div>
    <div class="content col-12 py-2 text-muted">
      <app-st-card-view-row title="Sub Total" [boldTitle]="false">
        <app-value-display [value]="selectedTransaction.subTotal" type="currency"></app-value-display>
      </app-st-card-view-row>
      <app-st-card-view-row title="Taxes" [boldTitle]="false">
        <app-value-display [value]="selectedTransaction.totalTax" type="currency"></app-value-display>
      </app-st-card-view-row>
      <app-st-card-view-row title="Shipping" [boldTitle]="false">
        <app-value-display [value]="selectedTransaction.shippingMethod.price" type="currency"></app-value-display>
      </app-st-card-view-row>
      <app-st-card-view-row title="Discount" [boldTitle]="false">
        <app-value-display [value]="selectedTransaction.discountTotal * -1" type="currency"></app-value-display>
      </app-st-card-view-row>
    </div>
    <div class="content col-12 py-2 text-muted">
      <app-st-card-view-row title="Card Amount Used" [boldTitle]="false">
        <app-value-display [value]="selectedTransaction.paymentResponse?.CardAmount" type="currency"></app-value-display>
      </app-st-card-view-row>
      <app-st-card-view-row title="Reward Amount Used" [boldTitle]="false">
        <app-value-display [value]="selectedTransaction.paymentResponse?.PrefundedAmount" type="currency"></app-value-display>
      </app-st-card-view-row>
      <app-st-card-view-row title="Total Amount Approved" [boldTitle]="false">
        <app-value-display [value]="selectedTransaction.paymentResponse?.TotalAmountApproved" type="currency"></app-value-display>
      </app-st-card-view-row>
    </div>
    <div class="content col-12 py-2">
      <app-st-card-view-row title="Total Amount">
            <span class="font-weight-bold">
              <app-value-display [value]="selectedTransaction.grandTotal" type="currency"></app-value-display>
            </span>
      </app-st-card-view-row>
    </div>
  </div>
<!--  <ng-template pTemplate="footer">-->
<!--    <div class="d-flex justify-content-end">-->
<!--      ACTIONS-->
<!--    </div>-->
<!--  </ng-template>-->
</p-dialog>
