import { Injectable } from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {catchError} from 'rxjs/operators';
import {WooStore} from '../domain/wc-wrapper/woo-store';
import {WooCallback} from '../domain/wc-wrapper/woo-callback';
import {WooStorePatch} from '../domain/wc-wrapper/woo-store-patch';

@Injectable({
  providedIn: 'root'
})
export class WcWrapperService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  createStoreCredential(storeURL: string, req: WooCallback): Observable<any> {
    return this.http.post<WooCallback>(`${environment.wcWrapperUrl}/auth-callback`, req, {params: {storeURL}})
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  getStores(brandId: string): Observable<WooStore[]> {
    return this.http.get<WooStore[]>(`${environment.wcWrapperUrl}/organizations/${brandId}/stores`)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  editStore(brandId: string, storeId: string, req: WooStorePatch): Observable<WooStore> {
    return this.http.patch<WooStore>(`${environment.wcWrapperUrl}/organizations/${brandId}/stores/${storeId}`, req)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }

  deleteStoreCredential(brandId: string, storeId: string): Observable<any> {
    return this.http.delete<any>(`${environment.wcWrapperUrl}/organizations/${brandId}/stores/${storeId}`)
      .pipe(
        catchError(
          this.handleError
        )
      );
  }
}
