
<div class="row">

  <p-table [value]="transactions"  [columns]="cols" [rows]="25" [paginator]="true" [pageLinks]="8" [rowsPerPageOptions]="[25,50,100]" [responsive]="true"
           [loading]="loading"   (onLazyLoad)="loadTransactionsLazy($event)" [totalRecords]="totalRecords" [lazy]="true"  [autoLayout]="true"
           class="w-100" sortField="created" [sortOrder]="-1" styleClass="p-datatable-gridlines" #dt>
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of cols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-transaction>
      <tr>
        <td>
          <span class="d-none d-md-block">{{transaction.transactionDate | date:'medium'}}</span>
          <span class="d-block d-md-none">{{transaction.transactionDate | date:'shortDate'}}</span>
        </td>
        <td>
          {{transaction.cardBrand}} {{transaction.lastDigits}}<br/>
          {{transaction.carType}}
        </td>
        <td>
          {{transaction.amount | currency:'USD'}}
        </td>
        <td>
          {{transaction.merchantName}}
        </td>
        <td>
          {{transaction.city}}<br/>
          {{ transaction.zipcode}}
        </td>
        <td>
          {{transaction.industry}}:<br/>
          {{transaction.category}}
        </td>
        <td>
          <i *ngIf="transaction.type === 'PURCHASE'" style="color:green" class="fa fa-dollar fa-lg mr-1" pTooltip="{{transaction.type}}"></i>
          <i *ngIf="transaction.type === 'REFUND'" style="color:blue" class="fa fa-strikethrough fa-lg mr-1" pTooltip="{{transaction.type}}"></i>
          <i *ngIf="transaction.type === 'UNKNOWN'" style="color:gray" class="fa fa-question-circle-o fa-lg mr-1" pTooltip="{{transaction.type}}"></i>
        </td>
        <td>
          <i *ngIf="transaction.status === 'APPROVED'" style="color:green" class="fa fa-check-circle-o fa-lg mr-1" pTooltip="{{transaction.status}}"></i>
          <i *ngIf="transaction.status === 'DECLINED'" style="color:red" class="fa fa-times-circle-o fa-lg mr-1" pTooltip="{{transaction.status}}"></i>
          <i *ngIf="transaction.status === 'REFUNDED'" style="color:blue" class="fa fa-strikethrough fa-lg mr-1" pTooltip="{{transaction.status}}"></i>
          <i *ngIf="transaction.status === 'UNKNOWN'" style="color:gray" class="fa fa-question-circle-o fa-lg mr-1" pTooltip="{{transaction.status}}"></i>
        </td>
        <td>
          {{transaction.transactionId}}
        </td>
        <td>
          {{transaction.tokenId}}
        </td>
        <td>
          {{transaction.cardId}}
        </td>
        <td>
          {{transaction.mobileId}}
        </td>
        <td>
          {{transaction.valetId}}
        </td>
        <td>
          <button type="button" pButton label="View" (click)="selectTransaction(transaction)"></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <tr *ngIf="totalRecords != null && totalRecords > 0">
        <td [attr.colspan]="cols.length">
          <div class="text-center">{{ totalRecords }} results</div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="cols.length" class="text-center w-100">
          No transactions found.
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog #detailPanel header="Transaction Details" modal="true" [(visible)]="detailVisible">
    <pre>
      {{ selectedTransaction | json}}
    </pre>
</p-dialog>
