import {Component, Input, OnInit} from '@angular/core';
import {OffersUser} from '../../../domain/offers/user';
import {OffersService} from '../../../services/offers/offers-api.service';
import {AuthService} from "../../../services/auth.service";
import {AuthRoles} from "../../../domain/auth/auth-roles";

@Component({
  selector: 'app-offers-user-card',
  templateUrl: './offers-user-card.component.html',
  styleUrls: ['./offers-user-card.component.css']
})
export class OffersUserCardComponent implements OnInit {

  @Input()
  consumerId: string;

  loading = false;

  offersUser: OffersUser;
  holdReasonInput = '';
  showHoldConfirmation = false;
  freezeDisable = true;

  constructor(private offersService: OffersService,
              private authService: AuthService) { }

  ngOnInit(): void {
    this.getOffersUser()
    this.authService.hasRole([AuthRoles.ADMIN, AuthRoles.SUPPORT]).subscribe(result => this.freezeDisable = !result);
  }

  getOffersUser() {
    this.loading = true;
    this.offersService.getOffersUser(this.consumerId)
      .subscribe(offersUser => {
        this.loading = false;
        this.offersUser = offersUser;
      }, error => {
        this.loading = false;
        if (error.code === 10040) {
          this.offersUser = new OffersUser();
          return;
        } else {
          this.offersUser = null;
        }
      });
  }

  toggleCreditHold(event) {
    let checked = event.checked;
    this.loading = true;
    this.offersService.setCreditLimit(this.offersUser.uuid, checked).subscribe(result => {
      console.log('Credit limit: ' + event.checked);
      this.loading = false;
    }, error => {
      this.offersUser.creditLimit = !(event.checked);
      this.loading = false;
    });
  }

  toggleHold(event) {
    let checked = event.checked;
    if (checked) {
      this.showHoldConfirmation = true;
    } else {
      this.loading = true;
      this.offersService.setHold(this.offersUser.uuid, false).subscribe(updatedUser => {
        this.offersUser = updatedUser;
        this.loading = false;
      }, error => {
        // TODO error message
        this.offersUser.hold = true;
        this.loading = false;
      })
    }
  }

  confirmHold(confirmed) {
    console.error('ConfirmHold not implemented');

    if (confirmed) {
      this.showHoldConfirmation = false;
      this.offersService.setHold(this.consumerId, true, this.holdReasonInput).subscribe(updatedUser => {
        this.offersUser = updatedUser;
        this.holdReasonInput = '';
      }, error => {
        // TODO error message
        this.offersUser.hold = false;
      });
    } else {
      this.offersUser.hold = false;
      this.showHoldConfirmation = false;
    }
  }

  enrollInOffers() {
    this.offersService.createOffersUser(this.consumerId).subscribe(
      (response: OffersUser) => {
        this.offersUser = response;
      });
  }

}
