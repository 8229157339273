<div class="metric-card p-2 my-2 d-flex flex-row form-control">
  <div class="m-3 mr-4">
    <i class="{{icon}} metric-icon"></i>
  </div>
  <div class="w-100">
    <p class="m-0 mt-1" style="font-size: 2rem;"><strong>
      <app-value-display [value]="value"></app-value-display>
    </strong></p>
    <p class="metric-font">
      <ng-content selector="[text]"></ng-content>
    </p>
  </div>
  <div *ngIf="tooltip" class="w-auto">
    <i class="pi pi-info-circle metric-info-image" pTooltip="{{tooltip}}" tooltipStyleClass="metric-tooltip"></i>
  </div>
</div>
