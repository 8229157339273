import {Routes} from '@angular/router';
import {PageLoanApplicationComponent} from './pages/offers/page-loan-application/page-loan-application.component';
import {AccessGuard} from './services/access-guard';
import {PageReportsComponent} from './pages/legacy/page-reports/page-reports.component';
import {SummaryComponent} from './pages/legacy/summary/summary.component';
import {FormCreateUserComponent} from './pages/legacy/form-create-user/form-create-user.component';
import {PagePaymentsComponent} from './pages/offers/page-payments/page-payments.component';
import {PageCofTransactionsComponent} from './pages/payments/page-cof-transactions/page-cof-transactions.component';
import {PageMerchantsComponent} from './pages/payments/page-merchants/page-merchants.component';
import {PageInstrumentBlacklistComponent} from './pages/payments/page-instrument-blacklist/page-instrument-blacklist.component';
import {PageConsumerSearchComponent} from './pages/consumers/page-consumer-search/page-consumer-search.component';
import {PageUserDetailComponent} from './pages/page-user-detail/page-user-detail.component';
import {PageSearchTransactionsComponent} from './pages/hapi/page-search-transactions/page-search-transactions.component';
import {PageCreatedCardsComponent} from './pages/hapi/page-created-cards/page-created-cards.component';
import {PageOpenCardsComponent} from './pages/hapi/page-open-cards/page-open-cards.component';
import {PageSearchOffersUsersComponent} from './pages/offers/page-search-offers-users/page-search-offers-users.component';
import {PageRewardProgramsComponent} from './pages/offers/page-reward-programs/page-reward-programs.component';
import {PageRewardProgramDetailComponent} from './pages/offers/page-reward-program-detail/page-reward-program-detail.component';
import {PageCreateRewardProgramComponent} from './pages/offers/page-create-reward-program/page-create-reward-program.component';
import {PageRewardCampaignDetailsComponent} from './pages/offers/page-reward-campaign-details/page-reward-campaign-details.component';
import {PageRewardsComponent} from './pages/offers/page-rewards/page-rewards.component';
import {PageCampaignFormComponent} from './pages/offers/page-campaign-form/page-campaign-form.component';
import {PageCreateRewardTransactionComponent} from './pages/offers/page-create-reward-transaction/page-create-reward-transaction.component';
import {PageMerchantsOffersComponent} from './pages/offers/page-merchants/page-merchants-offers.component';
import {PageBrandsOffersComponent} from './pages/offers/page-brands/page-brands-offers.component';
import {PageCampaignsComponent} from './pages/offers/page-campaigns/page-campaigns.component';
import {MerchantDetailComponent} from './components/merchant-detail/merchant-detail.component';
import {PageMerchantDetailComponent} from './pages/offers/page-merchant-detail/page-merchant-detail.component';
import {PageActivitiesComponent} from './pages/offers/page-activities/page-activities.component';
import {PageActivityDetailComponent} from './pages/offers/page-activity-detail/page-activity-detail.component';
import {PageUnspentBalancesComponent} from './pages/offers/page-unspent-balances/page-unspent-balances.component';
import {PageTestAccountManagementComponent} from './pages/wallet/page-test-account-management/page-test-account-management.component';
import {FormKycComponent} from './pages/sherlock/form-kyc/form-kyc.component';
import {PageSearchKycComponent} from './pages/sherlock/page-search-kyc/page-search-kyc.component';
import {SherlockDetailComponent} from './pages/sherlock/sherlock-detail/sherlock-detail.component';
import {PageFirmwareComponent} from './pages/wallet/page-firmware/page-firmware.component';
import {PageWalletBinsComponent} from './pages/wallet/page-wallet-bins/page-wallet-bins.component';
import {PageValetWhitelistComponent} from './pages/wallet/page-valet-whitelist/page-valet-whitelist.component';
import {PageValetBlacklistComponent} from './pages/wallet/page-valet-blacklist/page-valet-blacklist.component';
import {PageMobileBlacklistComponent} from './pages/wallet/page-mobile-blacklist/page-mobile-blacklist.component';
import {PageLockedAccountsComponent} from './pages/wallet/page-locked-accounts/page-locked-accounts.component';
import {PageOrganizationsComponent} from './pages/enterprise/page-organizations/page-organizations.component';
import {PageOrganizationDetailComponent} from './pages/enterprise/page-organization-detail/page-organization-detail.component';
import {PageMerchantPaymentFormComponent} from './pages/offers/page-merchant-payment-form/page-merchant-payment-form.component';
import {OnboardingBrandComponent} from './pages/onboarding/onboarding-brand/onboarding-brand.component';
import {PageBillersComponent} from './pages/billing/page-billers/page-billers.component';
import {PageBspsComponent} from './pages/billing/page-bsps/page-bsps.component';
import {PageBillingHistoryComponent} from './pages/enterprise/page-billing-history/page-billing-history.component';
import {ActivityFeedComponent} from './pages/activity-feed/activity-feed.component';
import {PageLoginComponent} from './pages/page-login/page-login.component';
import {PageDeploymentsComponent} from './pages/page-deployments/page-deployments.component';
import {PageAffiliatesComponent} from "./pages/affiliate/page-affiliates/page-affiliates.component";
import {
  PageSearchClosedLoopTransactionsComponent
} from "./pages/hapi/page-search-closed-loop-transactions/page-search-closed-loop-transactions.component";
import {PageConsumerLeadsComponent} from "./pages/consumers/page-consumer-leads/page-consumer-leads.component";

export const appRoutes: Routes = [
  /*
   * DEPRECATED
   */

  { path: 'application', component: PageLoanApplicationComponent, canActivate: [ AccessGuard ]  },
  { path: 'reports', component: PageReportsComponent, canActivate: [ AccessGuard ]  },
  { path: 'summary', component: SummaryComponent, canActivate: [ AccessGuard ]  },
  { path: 'user', component: FormCreateUserComponent, canActivate: [ AccessGuard ]  },

  /*
   * Payments
   */

  { path: 'payments', component: PagePaymentsComponent, data: {roles: ['admin', 'support', 'accounting', 'dev']}, canActivate: [ AccessGuard ]  },
  { path: 'payments/transactions', component: PageCofTransactionsComponent, data: {roles: ['admin', 'support', 'accounting', 'dev']}, canActivate: [ AccessGuard ]  },
  { path: 'payments/merchants', component: PageMerchantsComponent, data: {roles: ['admin', 'support', 'accounting', 'dev']}, canActivate: [ AccessGuard ]  },
  { path: 'payments/instruments/blacklist', component: PageInstrumentBlacklistComponent, data: {roles: ['admin', 'support', 'dev']}, canActivate: [ AccessGuard ]  },

  /*
   * Consumers
   */

  { path: 'users', component: PageConsumerSearchComponent, data: {roles: ['admin', 'support', 'dev']}, canActivate: [ AccessGuard ]  },
  { path: 'user/:uuid', component: PageUserDetailComponent, data: {roles: ['admin', 'support', 'dev']}, canActivate: [ AccessGuard ]  },
  { path: 'leads', component: PageConsumerLeadsComponent, data: {roles: ['admin', 'support', 'dev']}, canActivate: [ AccessGuard ]  },

  /*
   * HAPI
   */

  { path: 'transactions', component: PageSearchTransactionsComponent, data: {roles: ['admin', 'support', 'accounting', 'dev']}, canActivate: [ AccessGuard ]  },
  { path: 'hapi/transactions', component: PageSearchTransactionsComponent, data: {roles: ['admin', 'support', 'accounting', 'dev']}, canActivate: [ AccessGuard ]  },
  { path: 'hapi/closedlooptransactions', component: PageSearchClosedLoopTransactionsComponent, data: {roles: ['admin', 'support', 'accounting', 'dev']}, canActivate: [ AccessGuard ]  },
  { path: 'hapi/cards/created', component: PageCreatedCardsComponent, data: {roles: ['admin', 'support', 'accounting', 'dev']}, canActivate: [ AccessGuard ]  },
  { path: 'hapi/cards/open', component: PageOpenCardsComponent, data: {roles: ['admin', 'support', 'accounting', 'dev']}, canActivate: [ AccessGuard ]  },

  /*
   * Offers
   */

  { path: 'offers/users', component: PageSearchOffersUsersComponent, data: {roles: ['admin', 'support', 'dev']}, canActivate: [ AccessGuard ]  },
  { path: 'offers/rewardprograms', component: PageRewardProgramsComponent, data: {roles: ['admin', 'support', 'accounting', 'dev']}, canActivate: [ AccessGuard ]  },
  { path: 'offers/rewardprogram/:uuid', component: PageRewardProgramDetailComponent, data: {roles: ['admin', 'support', 'accounting', 'dev']}, canActivate: [ AccessGuard ]  },
  { path: 'offers/rewardprogram', component: PageCreateRewardProgramComponent, data: {roles: ['admin', 'support']}, canActivate: [ AccessGuard ]  },
  { path: 'offers/rewardcampaign/:uuid', component: PageRewardCampaignDetailsComponent, data: {roles: ['admin', 'support', 'accounting', 'dev']}, canActivate: [ AccessGuard ]  },
  { path: 'offers/rewardcampaigns', component: PageRewardsComponent, data: {roles: ['admin', 'support', 'accounting', 'dev']}, canActivate: [ AccessGuard ]  },
  { path: 'offers/rewardcampaign', component: PageCampaignFormComponent, data: {roles: ['admin', 'support', 'dev']}, canActivate: [ AccessGuard ]  },
  { path: 'offers/rewardtransaction', component: PageCreateRewardTransactionComponent, data: {roles: ['admin', 'support', 'dev']}, canActivate: [ AccessGuard ]  },
  { path: 'offers/merchants', component: PageMerchantsOffersComponent, data: {roles: ['admin', 'support', 'dev']}, canActivate: [ AccessGuard ]  },
  { path: 'offers/brands', component: PageBrandsOffersComponent, data: {roles: ['admin', 'support', 'accounting', 'dev']}, canActivate: [ AccessGuard ]  },
  { path: 'offers/campaign', component: PageCampaignFormComponent, data: {roles: ['admin', 'support']}, canActivate: [ AccessGuard ]  },
  { path: 'offers/campaign/:id', component: PageCampaignFormComponent, data: {roles: ['admin', 'support']}, canActivate: [ AccessGuard ]  },
  { path: 'offers/campaigns', component: PageCampaignsComponent, data: {roles: ['admin', 'support']}, canActivate: [ AccessGuard ]  },
  { path: 'offers/old/merchant/:uuid', component: MerchantDetailComponent, data: {roles: ['admin', 'support', 'dev']}, canActivate: [ AccessGuard ]  },
  { path: 'offers/merchant/:uuid', component: PageMerchantDetailComponent, data: {roles: ['admin', 'support', 'accounting', 'dev']}, canActivate: [ AccessGuard ]  },
  { path: 'offers/activities', component: PageActivitiesComponent, data: {roles: ['admin', 'support', 'accounting', 'dev']}, canActivate: [ AccessGuard ]  },
  { path: 'offers/activity/:uuid', component: PageActivityDetailComponent, data: {roles: ['admin', 'support', 'accounting', 'dev']}, canActivate: [ AccessGuard ]  },
  { path: 'offers/unspentbalances', component: PageUnspentBalancesComponent, data: {roles: ['admin', 'support', 'accounting', 'dev']}, canActivate: [ AccessGuard ]  },
  { path: 'offers/testaccounts', component: PageTestAccountManagementComponent, data: {roles: ['admin', 'support', 'dev']},  canActivate: [ AccessGuard ]  },

  /*
   * Sherlock
   */

  { path: 'sherlock/kyc', component: FormKycComponent, data: {roles: ['admin', 'support', 'accounting', 'dev']}, canActivate: [ AccessGuard ]  },
  { path: 'sherlock/kyc/list', component: PageSearchKycComponent, data: {roles: ['admin', 'support', 'accounting', 'dev']}, canActivate: [ AccessGuard ]  },
  { path: 'sherlock/kyc/:id', component: SherlockDetailComponent, data: {roles: ['admin', 'support', 'accounting', 'dev']}, canActivate: [ AccessGuard ]  },

  /*
   * Wallet
   */

  { path: 'wallet/versions', component: PageFirmwareComponent, data: {roles: ['admin', 'dev']},  canActivate: [ AccessGuard ]  },
  { path: 'wallet/bins', component: PageWalletBinsComponent, data: {roles: ['admin', 'support', 'dev']},  canActivate: [ AccessGuard ]  },
  { path: 'wallet/valet/whitelist', component: PageValetWhitelistComponent, data: {roles: ['admin', 'support', 'dev']},  canActivate: [ AccessGuard ]  },
  { path: 'wallet/valet/blacklist', component: PageValetBlacklistComponent, data: {roles: ['admin', 'support', 'dev']},  canActivate: [ AccessGuard ]  },
  { path: 'wallet/mobile/blacklist', component: PageMobileBlacklistComponent, data: {roles: ['admin', 'support', 'dev']},  canActivate: [ AccessGuard ]  },
  { path: 'wallet/locked', component: PageLockedAccountsComponent, data: {roles: ['admin', 'support', 'dev']},  canActivate: [ AccessGuard ]  },


  /*
   * Enterprise
   */

  { path: 'enterprise/organizations', component: PageOrganizationsComponent, data: {roles: ['admin', 'support', 'accounting', 'dev']},  canActivate: [ AccessGuard ]  },
  { path: 'enterprise/organization/:uuid', component: PageOrganizationDetailComponent, data: {roles: ['admin', 'support', 'accounting', 'dev']},  canActivate: [ AccessGuard ]  },
  { path: 'enterprise/organization/:uuid/paymentform', component: PageMerchantPaymentFormComponent, data: {roles: ['admin', 'support', 'accounting']},  canActivate: [ AccessGuard ]  },
  { path: 'enterprise/onboarding', component: OnboardingBrandComponent, data: {roles: ['admin', 'support', 'accounting']}, canActivate: [ AccessGuard ]  },

  /*
   * Affiliate
   */

  { path: 'affiliates', component: PageAffiliatesComponent, data: {roles: ['admin', 'support']},  canActivate: [ AccessGuard ]  },

  /*
   * Billing
   */

  { path: 'billing/billers', component: PageBillersComponent, data: {roles: ['admin', 'support', 'dev']},  canActivate: [ AccessGuard ]  },
  { path: 'billing/bsps', component: PageBspsComponent, data: {roles: ['admin', 'support', 'dev']},  canActivate: [ AccessGuard ]  },
  { path: 'billing/billinghistory', component: PageBillingHistoryComponent, data: {roles: ['accounting']}, canActivate: [ AccessGuard ]  },

  /*
   * General
   */

  { path: 'feed', component: ActivityFeedComponent,  data: {roles: ['admin', 'support', 'dev'], requiresLogin: false}, canActivate: [ AccessGuard ]  },
  { path: '', component: PageLoginComponent, data: {requiresLogin: false}},
  { path: 'deployments', component: PageDeploymentsComponent, data: {roles: ['admin', 'support', 'dev'], requiresLogin: false}, canActivate: [ AccessGuard ]  },
];
