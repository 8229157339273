
<div class="row">
  <p-table [value]="events"  [columns]="cols" [rows]="25" [paginator]="true" [pageLinks]="8" [rowsPerPageOptions]="[25,50,100]" [responsive]="true"
           [loading]="loading"   (onLazyLoad)="loadEventsLazy($event)"   [totalRecords]="totalRecords" [lazy]="true"  [autoLayout]="true"
           class="w-100" sortField="created" [sortOrder]="-1" styleClass="p-datatable-gridlines" #dt>
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of cols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-event>
      <tr>
        <td>
          <span class="d-none d-md-block">{{event.registered | date:'medium'}}</span>
          <span class="d-block d-md-none">{{event.registered | date:'shortDate'}}</span>
        </td>
        <td>
          {{event.eventType}}
        </td>
        <td>
          {{event.instrumentId}}
        </td>
        <td>
          {{event.lastDigits}}
        </td>
        <td>
          {{event.output}}
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <tr *ngIf="totalRecords != null && totalRecords > 0">
        <td [attr.colspan]="cols.length">
          <div class="text-center">{{ totalRecords }} results</div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="cols.length" class="text-center w-100">
          No transactions found.
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
