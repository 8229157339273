import {Component, OnInit, ViewChild} from '@angular/core';
import {WalletBin} from '../../../domain/wallet/bin';
import {Table} from 'primeng/table';
import {WalletService} from '../../../services/wallet.service';
import {LazyLoadEvent, MessageService} from 'primeng/api';
import {PageRequest} from '../../../domain/common/paging';
import {PaymentsService} from '../../../services/payments.service';
import {Merchant} from '../../../domain/payments/merchant';
import {AuthService} from "../../../services/auth.service";
import {AuthRoles} from "../../../domain/auth/auth-roles";

@Component({
  selector: 'app-page-merchants',
  templateUrl: './page-merchants.component.html',
  styleUrls: ['./page-merchants.component.css']
})
export class PageMerchantsComponent implements OnInit {

  editDisable = true;
  deleteDisable = true;
  newDisable = true;

  merchants: Merchant[] = [];
  loading = true;
  cols: any[];
  formVisible = false;
  selectedItem: Merchant = null;

  totalRecords: number;

  @ViewChild('dt') datatable: Table;

  constructor(private paymentsService: PaymentsService,
              private messageService: MessageService,
              private authService: AuthService) { }

  ngOnInit(): void {
    this.cols = [
      { field: 'id', header: 'ID' },
      { field: 'name', header: 'Name' },
      { field: 'featured', header: 'Featured' },
      { field: 'enterpriseOrgname', header: 'Enterprise Org Name' },
      { field: 'orgId', header: 'Org ID' },
      { field: 'paymentGatewayCid', header: 'Payment Gateway CID' },
      { field: 'update', header: '' },
      { field: 'delete', header: '' }
    ];
    this.authService.hasRole([AuthRoles.ADMIN, AuthRoles.SUPPORT, AuthRoles.DEV]).subscribe(results => this.editDisable = !results);
    this.authService.hasRole([AuthRoles.ADMIN, AuthRoles.SUPPORT, AuthRoles.DEV]).subscribe(results => this.deleteDisable = !results);
    this.authService.hasRole([AuthRoles.ADMIN, AuthRoles.SUPPORT, AuthRoles.DEV]).subscribe(results => this.newDisable = !results);
  }

  getMerchants(pageRequest: PageRequest) {
    this.loading = true;
    this.paymentsService.getMerchants(pageRequest).subscribe(result => {
      this.totalRecords = result.totalElements;
      this.merchants = result.content;
      this.loading = false;
    }, err => {
      this.loading = false;
    });
  }

  loadDataLazy(event: LazyLoadEvent) {
    let page: number = event.first / event.rows;
    let pageRequest: PageRequest = {page: page, size: event.rows};
    this.getMerchants(pageRequest);
  }

  uploadCompleted(bin) {
      this.selectedItem = null;
      this.datatable.reset();
      this.formVisible = false;
  }

  update(merchant: Merchant) {
    this.loading = true;
    this.paymentsService.getMerchant(merchant.id).subscribe(result => {
      this.loading = false;
      this.selectedItem = result;
      this.formVisible = true;
    });
  }

  delete(merchant: Merchant) {
    this.loading = true;
    this.paymentsService.deleteMerchant(merchant.id).subscribe(result => {
      this.loadDataLazy(this.datatable.createLazyLoadMetadata());
    }, err => {
      this.loading = false;
      this.messageService.add({severity: 'error', summary: 'Error', detail: `Failed to delete merchant. ${err.message}`});
    });
  }

}
