import {Component} from '@angular/core';

@Component({
  selector: 'app-page-search-closed-loop-transactions',
  templateUrl: './page-search-closed-loop-transactions.component.html',
  styleUrls: ['./page-search-closed-loop-transactions.component.css']
})
export class PageSearchClosedLoopTransactionsComponent {
  constructor() {}
}
