<div class="container content">
  <app-page-header title='Affiliates'>
    <ng-container *ngIf="selectedTab === 0">
      <p-button label="New Affiliate Program" class="p-fluid" styleClass="p-button-sm"
                (onClick)="showNewAffiliateProgram()"></p-button>
      <p-button label="Link Affiliate Program" class="p-fluid" styleClass="p-button-outlined p-button-sm" icon="pi pi-link"
                (onClick)="showAffiliateProgramLinkForm = true;"></p-button>
    </ng-container>
    <ng-container *ngIf="selectedTab === 1">
      <p-button label="New Affiliate" class="p-fluid" styleClass="p-button-sm"
                (onClick)="showNewAffiliate()"></p-button>
    </ng-container>
  </app-page-header>
  <form [formGroup]="filterForm" class="row py-2" (ngSubmit)="doSearch()" (keydown.enter)="$event.preventDefault(); doSearch()" novalidate>

    <ng-container *ngIf="selectedTab === 0">
      <div class="form-group col-sm-12 col-md-6">
        <label class="text-uppercase" for="programName">Program Name</label>
        <input class="form-control" id="programName" type="text"
               placeholder="Filter by name" formControlName="programName">
      </div>
      <div class="form-group col-sm-12 col-md-6">
        <label for="programAffiliate" class="text-uppercase">Affiliate Name</label>
        <p-autoComplete appendTo="body" class="p-fluid" inputStyleClass="form-control" styleClass="p-0" id="programAffiliate"
                        placeholder="Filter by name" formControlName="programAffiliate"
                        [suggestions]="affiliatesFiltered" (completeMethod)="searchAffiliates($event)"
                        [dropdown]="true" field="name" [delay]="0" emptyMessage="No matches">
        </p-autoComplete>
      </div>
    </ng-container>

    <ng-container *ngIf="selectedTab === 1">
      <div class="form-group col-sm-12 col-md-6">
        <label class="text-uppercase" for="affiliateName">Affiliate Name</label>
        <input class="form-control" id="affiliateName" type="text"
               placeholder="Filter by name" formControlName="affiliateName">
      </div>
    </ng-container>

    <div class="col-12 text-right">
      <button class="btn btn-secondary btn-sm mr-2" (click)="clearForm()">Clear</button>
      <button type="submit" class="btn btn-primary btn-sm" [disabled]="disableSearch()" name="searchButton">
        <span>Search</span>
      </button>
    </div>
  </form>
  <p-tabView class="tabview"[(activeIndex)]="selectedTab" (onChange)="setupForTabChange()">
    <p-tabPanel header="Programs">
      <div class="row">
        <p-table [value]="programs" [columns]="programCols" [totalRecords]="programTotalRecords"
                 [autoLayout]="true" class="col-12" [loading]="loadingPrograms"
                 styleClass="p-datatable-gridlines" #programsDt>
          <ng-template pTemplate="header">
            <tr>
              <th *ngFor="let col of programCols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-program>
            <tr>
              <td *ngFor="let col of programCols" [ngSwitch]="col.field" >
                <ng-container *ngSwitchCase="'action'">
                  <button type="button" pButton pRipple icon="pi pi-ellipsis-v" class="p-button-rounded p-button-text" (click)="menu.toggle($event)"></button>
                  <p-menu #menu [popup]="true" [model]="getProgramActionOptions(program)" appendTo="body"></p-menu>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <app-value-display [value]="program[col.field]"></app-value-display>
                </ng-container>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td [attr.colspan]="programCols.length" class="text-center w-100">
                No programs found.
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer" *ngIf="programTotalRecords != null">
            <tr>
              <td [attr.colspan]="programCols.length">
                <div class="text-center">{{programTotalRecords}} results</div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Affiliates">
      <div class="row">
        <p-table [value]="affiliates" [columns]="affiliateCols" [totalRecords]="affiliateTotalRecords"
                 [autoLayout]="true" class="col-12" [loading]="loadingAffiliates"
                 styleClass="p-datatable-gridlines" #affiliatesDt>
          <ng-template pTemplate="header">
            <tr>
              <th *ngFor="let col of affiliateCols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-affiliate>
            <tr>
              <td *ngFor="let col of affiliateCols" [ngSwitch]="col.field" >
                <ng-container *ngSwitchCase="'action'">
                  <button type="button" pButton pRipple icon="pi pi-ellipsis-v" class="p-button-rounded p-button-text" (click)="menu.toggle($event)"></button>
                  <p-menu #menu [popup]="true" [model]="getAffiliateActionOptions(affiliate)" appendTo="body"></p-menu>
                </ng-container>
                <ng-container *ngSwitchCase="'numOfAffiliatedMerchant'">
                  <app-value-display [value]="affiliate?.affiliatedList ? affiliate.affiliatedList.length : 0"></app-value-display>
                </ng-container>
                <ng-container *ngSwitchCase="'createdAt'">
                  <app-value-display [value]="affiliate?.createdAt" type="timestamp"></app-value-display>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <app-value-display [value]="affiliate[col.field]"></app-value-display>
                </ng-container>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td [attr.colspan]="affiliateCols.length" class="text-center w-100">
                No affiliates found.
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer" *ngIf="affiliateTotalRecords != null">
            <tr>
              <td [attr.colspan]="affiliateCols.length">
                <div class="text-center">{{affiliateTotalRecords}} results</div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </p-tabPanel>
  </p-tabView>
</div>

<!-- Edit / New Affiliate Program -->
<p-dialog modal="modal" header="{{affiliateProgramFormInEditMode() ? 'Edit Affiliate Program' : 'New Affiliate Program' }}"
          [draggable]="false" [resizable]="false" [(visible)]="showAffiliateProgramForm" [dismissableMask]="true"
          [style]="{width: '600px'}" (onHide)="clearAffiliateProgramForm()">
  <ng-container *ngIf="!loadingAffiliateProgram">
    <form [formGroup]="affiliateProgramForm" class="p-1" (ngSubmit)="handleAffiliateProgramFormSubmit()" (keydown.enter)="$event.preventDefault()" novalidate>
      <div class="col-12">
        <div class="d-flex">
          <div class="form-group col-12">
            <label class="text-uppercase m-auto" for="name">Program Name</label>
            <input autocomplete="off" class="form-control" id="name" type="text"
                   formControlName="name" placeholder="Enter program name">
          </div>
        </div>
      </div>
      <ng-container formArrayName="programTerms">
        <ng-container *ngFor="let programTermFormGroup of affiliateProgramTerms?.controls; let i = index">
          <div class="col-12 d-flex flex-column align-items-center mb-2" [formGroup]="programTermFormGroup">
            <div class="col-12 d-flex justify-content-center position-relative">
              <p-button *ngIf="(i !== 0 || affiliateProgramTerms?.length > 1)&& !programTermFormGroup.get('id').value" icon="pi pi-times"
                        styleClass="p-button-text p-button-rounded p-button-danger" [style]="{'position':'absolute','top': '0px','right': '10%'}"
                        (click)="deleteProgramTermFormGroup(i)"></p-button>
              <p class="text-muted">Program Term</p>
            </div>
            <div class="col-12 d-flex align-items-center">
              <div class="col-12">
                <div>
                  <div class="col-12">
                    <label class="text-uppercase m-auto" for="startApplicationMonth{{i}}">Start Date</label>
                  </div>
                  <div class="form-group d-flex align-items-center">
                    <div class="col-4">
                      <input autocomplete="off" class="form-control" id="startApplicationMonth{{i}}" type="number" min="0"
                             formControlName="startApplicationMonth" aria-describedby="startDateHelp" placeholder="1">
                    </div>
                    <small class="text-muted col-8" id="startDateHelp{{i}}">month(s) after merchant sign up</small>
                  </div>
                </div>
                <div class="form-group col-12">
                  <label class="text-uppercase m-auto" for="type{{i}}">Program Term Type</label>
                  <p-dropdown appendTo="body" class="p-fluid" styleClass="form-control p-0" [autoDisplayFirst]="false"
                              [options]="programTermTypes" formControlName="type" id="type{{i}}" placeholder="Select type"></p-dropdown>
                </div>
                <div class="form-group col-12">
                  <label class="text-uppercase m-auto" for="currency{{i}}">Currency</label>
                  <p-dropdown appendTo="body" class="p-fluid" styleClass="form-control p-0" [autoDisplayFirst]="false"
                              [options]="programUnitType" formControlName="currency" id="currency{{i}}" placeholder="Select currency"></p-dropdown>
                </div>
                <div class="form-group col-12">
                  <label class="text-uppercase m-auto" for="amount{{i}}">Amount</label>
                  <input autocomplete="off" class="form-control" id="amount{{i}}" type="number"
                         placeholder="Enter amount" formControlName="amount">
                </div>
<!--                <div class="form-group col-12">-->
<!--                  <label class="text-uppercase m-auto" for="frequency">End Active Date</label>-->
<!--                  <p-dropdown appendTo="body" class="p-fluid" styleClass="form-control p-0" [autoDisplayFirst]="false"-->
<!--                              [options]="programTermFrequency" formControlName="frequency" id="frequency"></p-dropdown>-->
<!--                </div>-->
                <div>
                  <div class="col-12">
                    <label class="text-uppercase m-auto" for="endApplicationMonth{{i}}">Term Valid End Date</label>
                  </div>
                  <div class="form-group d-flex align-items-center">
                    <div class="col-4">
                      <input autocomplete="off" class="form-control" id="endApplicationMonth{{i}}" type="number" min="0"
                             formControlName="endApplicationMonth" aria-describedby="endDateHelp" placeholder="30">
                    </div>
                    <small class="text-muted" id="endDateHelp">months after merchant start date</small>
                  </div>
                </div>
              </div>
            </div>
            <ng-container *ngIf="i + 1 !== affiliateProgramTerms?.length">
              <hr class="col-12">
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
      <div class="col-12 d-flex justify-content-center">
        <p-button label="Add Term" class="p-fluid" styleClass="p-button-primary p-button-text"
                  (onClick)="addAffiliateProgramTermEntry()"></p-button>
      </div>
      <div class="text-left col">
        <button type="submit" class="btn btn-primary btn-custom" [disabled]="!this.affiliateProgramForm.valid || !this.affiliateProgramForm.dirty">
          <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
          <span *ngIf="!loading">Save</span>
        </button>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="loadingAffiliateProgram">
    <div class="col"></div>
    <div class="text-center col">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div class="col"></div>
  </ng-container>
</p-dialog>

<!-- Affiliate Program Details -->
<p-dialog modal="modal" header="{{selectedProgram?.name}} Program"
          [draggable]="false" [resizable]="false" [(visible)]="showSelectedProgram" [dismissableMask]="true"
          [style]="{width: '900px'}" (onHide)="showSelectedProgram = false; selectedProgram = null;">
  <ng-container *ngIf="!loadingSelectedProgram">
    <div class="content col-12">
      <div class="col-12 d-flex py-2 px-0">
        <div class="d-flex flex-column col-9 p-0">
          <div class="font-weight-bold">
            Program ID
          </div>
          <app-value-display [value]="selectedProgram?.id"></app-value-display>
        </div>
        <div class="d-flex flex-column col-3 p-0 align-items-end">
          <button class="btn btn-primary btn-sm mt-1 mb-1" (click)="showAffiliateProgramEditFromDetails()">Edit Program</button>
        </div>
      </div>
      <div class="col-12 d-flex py-2 px-0">
        <div class="d-flex flex-column col-9 p-0">
          <div class="font-weight-bold">
            Program Terms
          </div>
          <ng-container *ngIf="selectedProgramTerms?.length > 0">
            <ul>
              <ng-container *ngFor="let programTerm of selectedProgramTerms; let i = index">
                <li>{{programTerm.description}} ({{programTerm.unitType}}{{programTerm.amount}})</li>
              </ng-container>
            </ul>
          </ng-container>
          <ng-container *ngIf="!selectedProgramTerms || selectedProgramTerms?.length === 0">
            <p class="m-0 text-muted font-italic">None</p>
          </ng-container>
        </div>
      </div>
      <div class="col-12 d-flex py-2 px-0">
        <div class="d-flex flex-column col-9 p-0">
          <div class="font-weight-bold">
            Number of Affiliates
          </div>
          <app-value-display [value]="selectedProgramAffiliates.length"></app-value-display>
        </div>
      </div>
    </div>
    <div class="col-12 my-3 font-weight-bold">
      Affilates attached to the program
    </div>
    <p-table [value]="selectedProgramAffiliates" [columns]="selectedProgramAffiliateCols" [paginator]="false"
             [autoLayout]="true"
             styleClass="p-datatable-gridlines" #selectedProgramAffiliatesDt>
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let col of selectedProgramAffiliateCols">{{col.header}} </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-program>
        <tr>
          <td *ngFor="let col of selectedProgramAffiliateCols" [ngSwitch]="col.field">
            <ng-container *ngSwitchDefault>
              <app-value-display [value]="program[col.field]"></app-value-display>
            </ng-container>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="selectedProgramAffiliateCols.length" class="text-center w-100">
            No affiliates found
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-container>
  <ng-container *ngIf="loadingSelectedProgram">
    <div class="col"></div>
    <div class="text-center col">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div class="col"></div>
  </ng-container>
</p-dialog>

<!-- Affiliate Program Link -->
<p-dialog modal="modal" header="Link Affiliate Program with Affiliate"
          [draggable]="false" [resizable]="false"
          [(visible)]="showAffiliateProgramLinkForm" [dismissableMask]="true"
          [style]="{width: '600px'}" (onHide)="clearAffiliateProgramLinkForm()">
  <ng-container *ngIf="!loadingAffiliateProgramLink">
    <form [formGroup]="affiliateProgramLinkForm" class="p-1" (ngSubmit)="handleAffiliateProgramLinkFormSubmit()"
          (keydown.enter)="$event.preventDefault()" novalidate>
      <div class="col-12 d-flex flex-column">
        <div class="form-group col-12">
          <label class="text-uppercase m-auto" for="program">Affiliate Program</label>
          <p-dropdown appendTo="body" class="p-fluid" styleClass="form-control p-0" [autoDisplayFirst]="false"
                      [options]="programs" formControlName="program"
                      id="program" placeholder="Select program"
                      optionLabel="name" optionValue="id"></p-dropdown>
        </div>
        <div class="form-group col-12">
          <label class="text-uppercase m-auto" for="affiliate">Affiliate</label>
          <p-dropdown appendTo="body" class="p-fluid" styleClass="form-control p-0" [autoDisplayFirst]="false"
                      [options]="affiliates" formControlName="affiliate"
                      id="affiliate" placeholder="Select affiliate"
                      optionLabel="name" optionValue="id"></p-dropdown>
        </div>
      </div>
      <div class="text-right col">
        <button type="submit" class="btn btn-primary btn-custom"
                [disabled]="!this.affiliateProgramLinkForm.valid || !this.affiliateProgramLinkForm.dirty">
          <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
          <span *ngIf="!loading">Save</span>
        </button>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="loadingAffiliateProgramLink">
    <div class="col"></div>
    <div class="text-center col">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div class="col"></div>
  </ng-container>
</p-dialog>

<!-- Edit / New Affiliate -->
<p-dialog modal="modal" header="{{affiliateFormInEditMode() ? 'Edit Affiliate' : 'New Affiliate' }}"
          [draggable]="false" [resizable]="false"
          [(visible)]="showAffiliateForm" [dismissableMask]="true"
          [style]="{width: '600px'}" (onHide)="clearAffiliateForm()">
  <ng-container *ngIf="!loadingAffiliate">
    <form [formGroup]="affiliateForm" class="p-1" (ngSubmit)="handleAffiliateFormSubmit()"
          (keydown.enter)="$event.preventDefault()" novalidate>
      <div class="col-12 d-flex flex-column">
        <div class="form-group col-12">
          <label class="text-uppercase m-auto" for="name">Name</label>
          <input autocomplete="off" class="form-control" id="name" type="text"
                 placeholder="Enter name" formControlName="name">
        </div>
        <div class="form-group col-12">
          <label class="text-uppercase m-auto" for="type">Affiliate Type</label>
          <p-dropdown appendTo="body" class="p-fluid" styleClass="form-control p-0" [autoDisplayFirst]="false"
                      [options]="affiliateTypes" formControlName="type" id="type" placeholder="Select type"></p-dropdown>
        </div>
        <div class="form-group col-12">
          <label class="text-uppercase m-auto" for="email">Email</label>
          <input autocomplete="off" class="form-control" id="email" type="email"
                 formControlName="email" placeholder="email@example.com" email>
        </div>
        <div class="form-group col-12">
          <label class="text-uppercase m-auto" for="phone">Phone Number</label>
          <input autocomplete="off" class="form-control" id="phone" type="text"
                 formControlName="phone" aria-describedby="phoneHelp" placeholder="Enter phone number">
          <small class="text-muted font-italic" id="phoneHelp">country code and then number, for example: '+11234567890'</small>
        </div>
      </div>
      <div class="text-left col">
        <button type="submit" class="btn btn-primary btn-custom"
                [disabled]="!this.affiliateForm.valid || !this.affiliateForm.dirty">
          <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
          <span *ngIf="!loading">Save</span>
        </button>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="loadingAffiliate">
    <div class="col"></div>
    <div class="text-center col">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div class="col"></div>
  </ng-container>
</p-dialog>

<!-- Affiliate Details -->
<p-dialog modal="modal" header="{{selectedAffiliate?.name}}"
          [draggable]="false" [resizable]="false" [(visible)]="showSelectedAffiliate" [dismissableMask]="true"
          [style]="{width: '900px'}" (onHide)="showSelectedAffiliate = false;selectedAffiliate = null;selectedAffiliateAffiliated = []">
  <ng-container *ngIf="!loadingSelectedAffiliate">
    <div class="content col-12">
      <div class="col-12 d-flex py-2 px-0">
        <div class="d-flex flex-column col-6 p-0">
          <div class="font-weight-bold">
            Affiliator ID
          </div>
          <app-value-display [value]="selectedAffiliate?.id"></app-value-display>
        </div>
        <div class="d-flex flex-column col-6 p-0 align-items-end">
          <div class="font-weight-bold">
            Affiliator Since
          </div>
          <app-value-display [value]="selectedAffiliate?.createdAt" [type]="'timestamp'"></app-value-display>
        </div>
      </div>

      <div class="col-12 d-flex py-2 px-0">
        <div class="d-flex flex-column col-6 p-0">
          <div class="font-weight-bold">
            Email Address
          </div>
          <app-value-display [value]="selectedAffiliate?.email"></app-value-display>
        </div>
        <div class="d-flex flex-column col-6 p-0 align-items-end">
<!--          filler-->
        </div>
      </div>

      <div class="col-12 d-flex py-2 px-0">
        <div class="d-flex flex-column col-6 p-0">
          <div class="font-weight-bold">
            Phone Number
          </div>
          <app-value-display [value]="selectedAffiliate?.phone"></app-value-display>
        </div>
        <div class="d-flex flex-column col-6 p-0 align-items-end">
<!--          filler-->
        </div>
      </div>

      <div class="col-12 d-flex py-2 px-0">
        <div class="d-flex flex-column col-6 p-0">
          <div class="font-weight-bold">
            Active Affiliate Programs
          </div>
          <ng-container *ngIf="selectedProgramTerms?.length > 0">
            <ul>
              <ng-container *ngFor="let program of selectedAffiliate?.programs; let i = index">
                <li>{{program.name}} ({{program.affiliationCode}})</li>
              </ng-container>
            </ul>
          </ng-container>
          <ng-container *ngIf="selectedProgramTerms?.length === 0">
            <p class="m-0 text-muted font-italic">None</p>
          </ng-container>
        </div>
        <div class="d-flex flex-column col-6 p-0  align-items-end">
          <div class="font-weight-bold">
            Number of Affiliated Merchants
          </div>
          {{selectedAffiliate?.affiliatedList ? selectedAffiliate?.affiliatedList.length : 0}}
        </div>
      </div>
    </div>
    <div class="col-12 my-3 font-weight-bold">
      Affilated Merchants for {{selectedAffiliate?.name}}
    </div>
    <p-table [value]="selectedAffiliateAffiliated" [columns]="selectedAffiliateAffiliatedCols" [paginator]="false"
             [autoLayout]="true"
             styleClass="p-datatable-gridlines" #selectedAffiliateAffiliatedDt>
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let col of selectedAffiliateAffiliatedCols">{{col.header}} </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-affiliateCondition>
        <tr>
          <td *ngFor="let col of selectedAffiliateAffiliatedCols" [ngSwitch]="col.field">
            <ng-container *ngSwitchCase="'id'">
              <app-value-display [value]="affiliateCondition?.organization.id"></app-value-display>
            </ng-container>
            <ng-container *ngSwitchCase="'name'">
              <app-value-display [value]="affiliateCondition?.organization.name"></app-value-display>
            </ng-container>
            <ng-container *ngSwitchCase="'validUntil'">
              <app-value-display [value]="affiliateCondition?.endDate" type="timestamp"></app-value-display>
            </ng-container>
            <ng-container *ngSwitchCase="'action'">
              <p-button label="Details" styleClass="p-button-link" (onClick)="goToOrganizationDetails(affiliateCondition.affiliatedId)"></p-button>
            </ng-container>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="selectedAffiliateAffiliatedCols.length" class="text-center w-100">
            No affiliates found
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-container>
  <ng-container *ngIf="loadingSelectedAffiliate">
    <div class="col"></div>
    <div class="text-center col">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div class="col"></div>
  </ng-container>
</p-dialog>

<!-- Affiliate Merchant Link -->
<p-dialog modal="modal" header="Affiliate Merchant for {{selectedAffiliateForLink?.name}}"
          [draggable]="false" [resizable]="false"
          [(visible)]="showAffiliateMerchantLinkForm" [dismissableMask]="true"
          [style]="{width: '600px'}" (onHide)="clearAffiliateMerchantLinkForm()">
  <ng-container *ngIf="!loadingAffiliateMerchants">
    <form [formGroup]="affiliateMerchantLinkForm" class="p-1" (ngSubmit)="handleAffiliateMerchantLinkFormSubmit()"
          (keydown.enter)="$event.preventDefault()" novalidate>
      <div class="col-12 d-flex flex-column">
        <div class="form-group col-12">
          <label class="text-uppercase m-auto" for="affiliationCode">Affiliate Program</label>
          <p-dropdown appendTo="body" class="p-fluid" styleClass="form-control p-0" [autoDisplayFirst]="false"
                      [options]="selectedAffiliateForLink?.programs" formControlName="affiliationCode"
                      id="affiliationCode" placeholder="Select program" emptyMessage="No programs found, please link a program to this affiliate."
                      optionLabel="name" optionValue="affiliationCode"></p-dropdown>
        </div>
        <div class="form-group col-12">
          <label for="organization" class="text-uppercase">Affiliate Merchant</label>
          <p-autoComplete appendTo="body" class="d-block p-fluid m-auto" inputStyleClass="form-control"
                          id="organization" name="organization" placeholder="Search Organization by Name"
                          formControlName="organization" [suggestions]="affiliateMerchantsFiltered"
                          emptyMessage="No matches" [showEmptyMessage]="affiliateMerchantsFiltered.length === 0"
                          (completeMethod)="searchAffiliateMerchants($event)" field="name"></p-autoComplete>
        </div>
      </div>
      <div class="text-right col">
        <button type="submit" class="btn btn-primary btn-custom"
                [disabled]="!this.affiliateMerchantLinkForm.valid || !this.affiliateMerchantLinkForm.dirty">
          <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
          <span *ngIf="!loading">Save</span>
        </button>
      </div>
    </form>
  </ng-container>
  <ng-container *ngIf="loadingAffiliateMerchants">
    <div class="col"></div>
    <div class="text-center col">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div class="col"></div>
  </ng-container>
</p-dialog>

<!-- View Payments -->
<p-dialog modal="modal" header="Payment History for {{selectedAffiliateForPayments?.name}}"
          [draggable]="false" [resizable]="false"
          [(visible)]="showSelectedAffiliatePayments" [dismissableMask]="true"
          [style]="{width: '900px'}" (onHide)="showSelectedAffiliatePayments = false;">
  <ng-container *ngIf="!loadingAffiliatePayments">
    <div class="text-right col-12">
      <button class="btn btn-primary btn-sm mt-1 mb-1" (click)="displayAffiliatePaymentForm()">Create Payment</button>
    </div>
    <p-table [value]="selectedAffiliatePayments" [columns]="selectedAffiliatePaymentsCols" [paginator]="false"
             [autoLayout]="true"
             styleClass="p-datatable-gridlines" #selectedAffiliatePaymentsDt>
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let col of selectedAffiliatePaymentsCols">{{col.header}}</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-payment>
        <tr>
          <td *ngFor="let col of selectedAffiliatePaymentsCols" [ngSwitch]="col.field">
            <ng-container *ngSwitchCase="'date'">
              <app-value-display [value]="payment?.paymentDate" type="timestamp"></app-value-display>
            </ng-container>
            <ng-container *ngSwitchCase="'amount'">
              <app-value-display [value]="payment?.amount" type="currency"></app-value-display>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <app-value-display [value]="payment[col.field]"></app-value-display>
            </ng-container>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="selectedAffiliatePaymentsCols.length" class="text-center w-100">
            No payments found
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-container>
  <ng-container *ngIf="loadingAffiliatePayments">
    <div class="col"></div>
    <div class="text-center col">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div class="col"></div>
  </ng-container>
</p-dialog>

<!-- New Payment -->
<p-dialog modal="modal" header="Register New Payment"
          [draggable]="false" [resizable]="false"
          [(visible)]="showAffiliatePaymentForm" [dismissableMask]="true"
          [style]="{width: '600px'}" (onHide)="clearAffiliatePaymentForm()">
  <form [formGroup]="affiliatePaymentForm" class="p-1" (ngSubmit)="handleAffiliatePaymentFormSubmit()"
        (keydown.enter)="$event.preventDefault()" novalidate>
    <div class="col-12 d-flex flex-column">
      <div class="form-group col-12">
        <label class="text-uppercase m-auto" for="date">Date</label>
        <p-calendar appendTo="body" class="p-fluid" inputStyleClass="form-control"
                    id="date" formControlName="date" placeholder="Enter date" showTime="true"></p-calendar>
      </div>
      <div class="form-group col-12">
        <label class="text-uppercase m-auto" for="amount">Amount</label>
        <input autocomplete="off" class="form-control" id="amount" type="number" placeholder="0.00" formControlName="amount">
      </div>
    </div>
    <div class="text-right col">
      <button type="submit" class="btn btn-primary btn-custom"
              [disabled]="!this.affiliatePaymentForm.valid || !this.affiliatePaymentForm.dirty">
        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
        <span *ngIf="!loading">Create Payment</span>
      </button>
    </div>
  </form>
</p-dialog>
