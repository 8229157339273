import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-metric-card',
  templateUrl: './metric-card.component.html',
  styleUrls: ['./metric-card.component.css']
})
export class MetricCardComponent {
  @Input() icon: string;
  @Input() value: string;
  @Input() tooltip: string;
}
