import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-st-card-view-row',
  templateUrl: './st-card-view-row.component.html',
  styleUrls: ['./st-card-view-row.component.css']
})
export class StCardViewRowComponent implements OnInit {

  @Input() title: string;
  @Input() val: string;
  @Input() align: string = 'align-items-center';
  @Input() boldTitle: boolean = true;

  constructor() { }

  ngOnInit() {}
}
