import {Output, EventEmitter, Component, Input, OnInit} from '@angular/core';
import {Card} from '../../../domain/hapi/card';
import {ConfirmationService} from 'primeng/api';
import {WalletService} from '../../../services/wallet.service';
import {MessageService} from 'primeng/api';
import {AuthService} from "../../../services/auth.service";
import {ErrorDisplayService} from "../../../services/error-display.service";
import {HapiService} from "../../../services/hapi.service";
import {AdminLinkCardRequest} from "../../../domain/hapi/card/admin-link-card-request";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthRoles} from "../../../domain/auth/auth-roles";

@Component({
  selector: 'app-wallet-ovcard-card',
  templateUrl: './wallet-ovcard-card.component.html',
  styleUrls: ['./wallet-ovcard-card.component.css']
})
export class WalletOvcardCardComponent implements OnInit {

  @Input()
  consumerId: string;

  loading = false;

  topUpVisible = false;
  topUpDisable = true;
  topUpForm: FormGroup;

  failedGet = false;

  showLinkCardForm = false;
  linkCardForm: FormGroup;

  linkUsioDisable = true;

  ovCard: Card;
  @Output() notifyOvCardChange = new EventEmitter<Date>();

  constructor(private walletService: WalletService,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              private authService: AuthService,
              private errorDisplayService: ErrorDisplayService,
              private hapiService: HapiService,
              private fb: FormBuilder,
              private authSerivce: AuthService) { }

  ngOnInit(): void {
    this.getOvCard()
    this.authService.hasRole([AuthRoles.ADMIN]).subscribe(result => this.topUpDisable = !result);
    this.initializeForms();
    this.authService.hasRole([AuthRoles.ADMIN, AuthRoles.SUPPORT]).subscribe(result => this.linkUsioDisable = !result);
  }

  initializeForms() {
    this.topUpForm = this.fb.group({
      'amount': [null, [Validators.required]],
      'description': [null, [Validators.required]]
    });
    this.linkCardForm = this.fb.group({
      'processorCardId': [null, [Validators.required]]
    });
  }

  setOvCard(card: Card) {
    this.ovCard = card;
    this.notifyOvCardChange.emit(new Date());
  }

  getOvCard() {
    this.loading = true;
    this.walletService.getOvCard(this.consumerId).subscribe(result => {
      this.setOvCard(result.cardInfo?.primaryCard);
      this.loading = false;
    }, error => {
      this.failedGet = this.errorDisplayService.displayErrorResponse('Wallet OV Card', error);
      this.loading = false;
    });
  }

  closeCard() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to close this card?',
      header: 'Close Confirmation',
      icon: 'fa fa-trash',
      accept: () => {
        this.loading = true;
        this.walletService.closeOvCard(this.consumerId).subscribe(result => {
          this.setOvCard(null);
          this.loading = false;
        }, error => {
          this.errorDisplayService.displayErrorResponse('Wallet Close OV Card', error);
          this.loading = false;
        })
      },
      reject: () => {
        console.log('Cancelled card closure.')
      }
    });
  }

  mapCardType(str) {
    switch (str) {
      case 'ov_gpr':
        return 'OVCash';
      case 'ov_corporate_incentive':
        return 'OVRewards';
      default:
        return str;
    }
  }

  topUpCard() {
    let data = {
      amount: this.topUpForm.controls.amount.value,
      description: this.topUpForm.controls.description.value
    }
    this.walletService.manualOvCardTopUp(this.consumerId, data).subscribe(result => {
      this.topUpVisible = false;
      this.getOvCard();
    }, error => {
      this.errorDisplayService.displayErrorResponse('Wallet Top Up Card', error);
      this.topUpVisible = false;
    })
      .add(() => {
        this.topUpForm.reset();
      });
  }

  recoverOrLinkUsioCard() {
    const request: AdminLinkCardRequest = {
      userUuid: this.consumerId,
      processorCardId: this.linkCardForm.controls.processorCardId.value
    }
    this.hapiService.recoverOrLinkUsioCard(request).subscribe(result => {
      this.showLinkCardForm = false;
    }, error => {
      this.errorDisplayService.displayErrorResponse('Recover/Link Usio Card', error);
      this.showLinkCardForm = false;
    })
      .add(() => {
        this.linkCardForm.reset();
      });
  }
}
