<div class="container content">
  <app-page-header title='Reports'></app-page-header>
  <!--<pre>{{form?.value | json}}</pre>-->
  <form [formGroup]="form" class="row" (ngSubmit)="getReport()" (keydown.enter)="$event.preventDefault()" novalidate>



    <div class="form-group col-xs-12 col-sm-6 col-md-6" *ngIf="selectedReport.filters.indexOf(reportFilter.timePeriod) != -1">
      <label class="text-uppercase" for="startDateTime">Start Date</label>
      <p-calendar class="pull-right p-fluid" inputStyleClass="form-control" id="startDateTime" formControlName="startDateTime" ></p-calendar>
    </div>

    <div class="form-group col-xs-12 col-sm-6 col-md-6" *ngIf="selectedReport.filters.indexOf(reportFilter.timePeriod) != -1">
      <label class="text-uppercase" for="endDateTime">End Date</label>
      <p-calendar class="pull-right p-fluid" inputStyleClass="form-control" id="endDateTime" formControlName="endDateTime" ></p-calendar>
    </div>

    <div class="form-group col-xs-12 col-sm-6 col-md-6" *ngIf="selectedReport.filters.indexOf(reportFilter.groupedPeriod) != -1">
      <label class="text-uppercase" for="periodLength">Period Length</label>
      <p-dropdown class="pull-right p-fluid" styleClass="form-control p-0" [options]="periodOptions"
                  formControlName="periodLength" id="periodLength"></p-dropdown>
    </div>

    <div class="col-12 text-right">
      <button type="submit" class="btn btn-primary btn-sm" [disabled]="!form.valid || loading" name="searchButton" >
        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
        <span *ngIf="!loading">Search</span>
      </button>
    </div>

  </form>

  <hr>

  <p-tabView class="tabview"orientation="top" (onChange)="handleTabChange($event)">
    <p-tabPanel [header]="report.title" *ngFor="let report of availableReports; let i = index" [selected]="i == 0">
      <p-table (sortFunction)="customSort($event)" [customSort]="true"  [columns]="report.primeColumns" [value]="report.data" #reportTable  [exportFilename]="report.title">
        <ng-template pTemplate="caption">
          <div class="ui-helper-clearfix">
            <button type="button" class="btn btn-primary btn-sm pull-right" icon="fa-file-o" iconPos="left" (click)="reportTable.exportCSV()">Export CSV</button>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let columnName of report.columnNames">{{columnName}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row>
          <tr>
            <td *ngFor="let columnName of report.columnNames">{{row[columnName]}}</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr>
            <td *ngFor="let columnName of report.columnNames">
              <strong>{{ columnTotal(columnName) }}</strong>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-tabPanel>
  </p-tabView>
  <div class="row">
    <div class="col-12">
      <p-chart type="bar" [data]="chartData" [options]="chartOptions" ></p-chart>
    </div>
  </div>

</div>

<p-dialog header="Loading" [(visible)]="loading" width="300" [modal]="true" [closeOnEscape]="false" [closable]="false">
  Please wait...
</p-dialog>
