<div class="container content p-2" *ngIf="!obj?.uuid && !loading">
  <div class="row">
    <div class="col-12 text-center">
      <i class="fa fa-warning"></i><br/>
      Unable to load reward program.
    </div>
  </div>
</div>
<div class="container content pt-2" *ngIf="obj?.uuid" >
  <div class="row">
    <div class="col-8 col-md-9" >
        <h3>
          <app-edit-in-place [value]="obj?.title" (onSaved)="updateTitle($event)"></app-edit-in-place>
          <span *ngIf="obj?.removed"><small><p-chip label="Removed" [style]="{fontSize: '12px'}"></p-chip></small></span>
        </h3>
        <span class="text-muted "><app-copy-text [display]="obj?.uuid"></app-copy-text> </span>
    </div>
    <div class="col-4 col-md-3 text-right">
      <h4 class="text-muted">Reward Program</h4>
      <button [disabled]="deleteDisable" class="btn btn-danger" *ngIf="!obj?.removed" (click)="confirmDelete()">Delete</button>
    </div>
    <div class="col-12 py-0 my-0">
      <hr>
    </div>
  </div>

  <div class="row"  *ngIf="loading">
    <div class="col">&nbsp;</div>
    <div class="text-center col">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div class="col">&nbsp</div>
  </div>

  <p-tabView class="tabview"*ngIf="obj?.uuid && !loading">
    <p-tabPanel header="Details">
      <div class="row">
        <app-st-card-view title="Info" [loading]="loading"  class="col-12 col-md-6 col-lg-6" >
          <app-st-card-view-row title="Created" class="col-12">
            {{ obj?.created | date:'medium'}}
          </app-st-card-view-row>
          <app-st-card-view-row title="Updated" class="col-12" *ngIf="obj?.updated">
            {{ obj?.updated | date:'medium'}}
          </app-st-card-view-row>
          <app-st-card-view-row title="Discoverable" class="col-12" *ngIf="!!obj">
            <p-inputSwitch [disabled]="discoverableDisable" [(ngModel)]="obj.discoverable" (onChange)="updateDiscoverability($event)"></p-inputSwitch>
          </app-st-card-view-row>
          <app-st-card-view-row title="Expiration Period (Days)" class="col-12">
            <app-edit-in-place [value]="obj?.expirationPeriod" type="number" (onSaved)="updateExpirationPeriod($event)"></app-edit-in-place>
          </app-st-card-view-row>
          <app-st-card-view-row title="Preview Text" class="col-12">
            <app-edit-in-place [value]="obj?.previewText" type="text" (onSaved)="updatePreviewTextPeriod($event)"></app-edit-in-place>
          </app-st-card-view-row>
          <app-st-card-view-row title="Image" class="col-12">
            <app-edit-in-place [value]="obj?.image" type="image" (onSaved)="saveImage($event)"></app-edit-in-place>
          </app-st-card-view-row>
        </app-st-card-view>

        <app-st-card-view title="Brand" [loading]="loading"  class="col-12 col-md-6 col-lg-6">
          <ng-container class="st-card-subtitle text-muted">
            <small>
              <a routerLink="/offers/merchant/{{obj['brand']['uuid']}}" routerLinkActive="active" *ngIf="obj?.brand?.uuid">{{obj.brand.uuid}}</a>
            </small>
          </ng-container>
          <app-st-card-view-row title="Name" class="col-12">
            {{ obj?.brand?.name}}
          </app-st-card-view-row>
          <app-st-card-view-row title="Endpoint" class="col-12">
            <a href="{{ obj?.brand?.endpoint}}">{{obj?.brand?.endpoint}}</a>
          </app-st-card-view-row>
          <app-st-card-view-row title="Image" class="col-12" *ngIf="obj?.brand?.image">
            <img [src]="obj?.brand?.image" height="30" />
          </app-st-card-view-row>
        </app-st-card-view>

        <app-edit-rich-text-card [title]="'Description'" class="col-12 col-md-6 col-lg-6" [value]="obj?.description" (onSave)="saveDescription($event)">
        </app-edit-rich-text-card>

        <!--    <app-st-card-view [title]="'Fine Print'" class="col-12 col-md-6 col-lg-6" [loading]="loading" >-->
        <!--      <div class="m-3" [innerHTML]="obj?.finePrint" *ngIf="obj?.finePrint"></div>-->
        <!--    </app-st-card-view>-->

        <app-st-card-view title="Campaigns" [loading]="loading"  class="col-12 col-md-6 col-lg-6">
          <div class="st-card-subtitle text-muted">
            <button type="button" [disabled]="newCampaignDisable" class="btn btn-primary btn-sm" (click)="newCampaign()">New</button>
          </div>
          <ng-container  *ngFor="let campaign of obj?.rewardCampaigns">
            <app-st-card-view-row [title]="campaign?.title" class="col-12">
              <p class="m-0 p-0" style="color: {{getCampaignStatusColor(campaign)}};">{{getCampaignStatus(campaign)}}</p>
              <a routerLink="/offers/rewardcampaign/{{campaign['uuid']}}" routerLinkActive="active">View</a>
            </app-st-card-view-row>
          </ng-container>

        </app-st-card-view>

        <app-st-card-view title="MIDs" [loading]="loading"  class="col-12 col-md-6 col-lg-6">
          <div class="st-card-subtitle text-muted">
            <button type="button" [disabled]="newMerchantDisable" class="btn btn-primary btn-sm" (click)="showNewMidForm=true">New</button>
          </div>
          <ng-container  *ngFor="let mid of obj?.mids">
            <app-st-card-view-row [title]="mid?.mid?.alias ? mid?.mid?.alias : mid?.mid?.name" class="col-12">
              <button class="btn btn-link p-0 m-0" (click)="removeMid(mid.mid.uuid)" *ngIf="!mid?.mid?.rewardCampaign || mid?.mid?.rewardCampaign?.removed">Remove</button>
              <a routerLink="/offers/merchant/{{mid.mid?.uuid}}" routerLinkActive="active">View</a>
            </app-st-card-view-row>
          </ng-container>
          <hr>
<!--          <app-st-card-view-row [title]="" class="col-12" *ngIf="showMidForm">-->
<!--            &lt;!&ndash;        <input pInputText id="newName" type="text" placeholder="Name" value="" #newName />&ndash;&gt;-->
<!--            <input pInputText id="newMid" type="text" placeholder="MID String" value="" #newMid />-->
<!--            <button pButton label="Save" class="ml-1" (click)="addMidToProgram(newMid.value)"></button>-->
<!--            <button pButton label="Cancel" class="ml-1" (click)="showMidForm = false"></button>-->
<!--          </app-st-card-view-row>-->
        </app-st-card-view>

        <app-st-card-view title="Program Balance" [loading]="loading || fundsLoading"  class="col-12 col-md-6 col-lg-6" emptyMessage="No Balance Found">
          <div class="st-card-subtitle text-muted d-inline-flex" style="gap: .25rem;">
            <button class="btn btn-secondary btn-sm" (click)="exportFundLogsToCSV()">Export</button>
            <button [disabled]="fundLoadDisable" class="btn btn-primary btn-sm" (click)="showFundsForm=true">Load</button>
          </div>
          <app-st-card-view-row [title]="'Current Balance'" class="col-12" aria-describedby="currentBalanceHelp">
            <app-value-display [value]="obj.poolBalance" type="currency"></app-value-display>
          </app-st-card-view-row>
          <small class="form-text text-muted col-12 m-0 mb-3" id="currentBalanceHelp">Remaining balance for this reward program.</small>
          <app-st-card-view-row [title]="'Unspent Rewards'" class="col-12" aria-describedby="unspentRewardsHelp">
            <app-value-display [value]="obj.unspentRewards" type="currency"></app-value-display>
          </app-st-card-view-row>
          <small class="form-text text-muted col-12 m-0" id="unspentRewardsHelp">Total earned rewards that have not been spent, cancelled, or expired.</small>
          <app-st-card-view-row class="col-12">
            <button class="btn btn-link p-0 m-0" (click)="showFundsTable = true">View load history</button>
          </app-st-card-view-row>
        </app-st-card-view>

        <!--    <app-st-card-view title="Balance History" [loading]="loading"  class="col-12">-->
        <!--    </app-st-card-view>-->

        <app-st-card-view title="Program Rules" [loading]="loading"  class="col-12">
          <p class="pl-3 pr-3">
            1. Debit
            <ng-container *ngIf="checkIfNumber(baseDepositAmount)">
              <u>{{baseDepositAmount | currency:'USD'}}</u>
            </ng-container>
            <ng-container *ngIf="!checkIfNumber(baseDepositAmount)">
              <span class="text-muted">(Set Amount)</span>
            </ng-container>
            <!--        <app-edit-in-place class="d-inline-flex" [value]="baseDepositAmount" type="number">-->
            <!--        </app-edit-in-place>-->
            manually from the merchant's bank account when the program balance is
            <ng-container *ngIf="checkIfNumber(minFundsForDeposit)">
              <u>{{minFundsForDeposit | currency:'USD'}}</u>
            </ng-container>
            <ng-container *ngIf="!checkIfNumber(minFundsForDeposit)">
              <span class="text-muted">(Set Amount)</span>
            </ng-container>
            <!--        <app-edit-in-place class="d-inline-flex" [value]="minFundsForDeposit" type="number">-->
            <!--        </app-edit-in-place>-->
            or less.
          </p>

          <p class="pl-3 pr-3 w-100">
            2. Disable all campaigns when the program balance is
            <ng-container *ngIf="checkIfNumber(minFundsForCampaignDisable)">
              <u>{{minFundsForCampaignDisable | currency:'USD'}}</u>
            </ng-container>
            <ng-container *ngIf="!checkIfNumber(minFundsForCampaignDisable)">
              <span class="text-muted">(Set Amount)</span>
            </ng-container>
            <!--        <app-edit-in-place class="d-inline-flex" [value]="minFundsForCampaignDisable" type="number">-->
            <!--        </app-edit-in-place>-->
            or less (consumers cannot earn rewards).
          </p>

          <p class="pl-3 pr-3">
            3. Disable the reward program when the program balance is
            <ng-container *ngIf="checkIfNumber(minFundsForProgramDisable)">
              <u>{{minFundsForProgramDisable | currency:'USD'}}</u>
            </ng-container>
            <ng-container *ngIf="!checkIfNumber(minFundsForProgramDisable)">
              <span class="text-muted">(Set Amount)</span>
            </ng-container>
            <!--        <app-edit-in-place class="d-inline-flex" [value]="minFundsForProgramDisable" type="number">-->
            <!--        </app-edit-in-place>-->
            or less (consumers cannot spend rewards they have earned).
          </p>

          <p class="pl-3 pr-3">
            4. Unspent rewards automatically expire after {{obj?.expirationPeriod}} days.
          </p>
        </app-st-card-view>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Reward Activity">
      <div class="row">
        <div class="col-12">
          <button [disabled]="exportDisable" class="pull-right btn btn-secondary btn-sm mt-1 mb-1" (click)="exportRewardProgramRewardActivitiesToCSV()">Export data table</button>
        </div>
        <app-reward-program-log-table (exportFilterData)="handleExportFilerData($event)" [rewardProgramCreation]="obj?.created"
                                      [brandId]="obj?.brand?.uuid" [mids]="obj?.mids" [rewardCampaigns]="obj?.rewardCampaigns" [locations]="obj?.locations"
                                      class="col-12"></app-reward-program-log-table>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Transactions">
      <div class="row">
        <div class="col-12">
          <div class="pull-right d-flex flex-row" style="gap: .5rem;">
            <button [disabled]="exportDisable" class="btn btn-secondary btn-sm mt-1 mb-1" (click)="exportRewardProgramRewardTransactionsToCSV()">Export data table</button>
            <button [disabled]="exportDisable" class="btn btn-secondary btn-sm mt-1 mb-1" (click)="exportRewardProgramRewardTransactionsToCSV(true)">Export entire last month</button>
          </div>
        </div>
        <app-reward-program-transaction-table [uuid]="obj?.uuid" [rewardProgram]="this.obj"
                                              (exportFilterData)="handleExportFilerData($event)" class="col-12"></app-reward-program-transaction-table>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Subscribers">
      <div class="row">
        <div class="col-12">
          <div class="pull-right d-flex flex-row" style="gap: .5rem;">
            <button [disabled]="exportDisable" class="btn btn-secondary btn-sm mt-1 mb-1" (click)="exportRewardProgramRewardSubscribersToCSV()">Export data table</button>
          </div>
        </div>
        <app-reward-program-subscribers-table (exportFilterData)="handleExportFilerData($event)"
                                              [uuid]="obj?.uuid" class="col-12"></app-reward-program-subscribers-table>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Participating Locations">
      <div class="row">
        <p-table [value]="participatingLocations"  [columns]="participatingLocationsCols" [rows]="25" [paginator]="true" [pageLinks]="8" [rowsPerPageOptions]="[25,50,100]"
                 [autoLayout]="true" class="col-12" [loading]="loading"
                 [lazy]="true" (onLazyLoad)="loadLocationsLazy($event)" [totalRecords]="participatingLocationsTotalRecords" styleClass="p-datatable-gridlines" #locationsDT>
          <ng-template pTemplate="header">
            <tr>
              <th *ngFor="let col of participatingLocationsCols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-location>
            <tr>
              <td *ngFor="let col of participatingLocationsCols" [ngSwitch]="col.field" >
                <ng-container *ngSwitchCase="'created'">
                  <app-value-display [value]="location?.created" type="timestamp"></app-value-display>
                </ng-container>
                <ng-container *ngSwitchCase="'alias'">
                  <app-value-display [value]="location?.alias"></app-value-display>
                </ng-container>
                <ng-container *ngSwitchCase="'status'">
                  <i *ngIf="!location?.removed" style="color:green" class="fa fa-check-circle-o fa-lg" pTooltip="Active"></i>
                  <i *ngIf="location?.removed" style="color:red" class="fa fa-times-circle-o fa-lg" pTooltip="Removed"></i>
                </ng-container>
                <ng-container *ngSwitchCase="'channel'">
                  <app-value-display [value]="location?.channel"></app-value-display>
                </ng-container>
                <ng-container *ngSwitchCase="'endpoint'">
                  <app-value-display [value]="location?.endpoint"></app-value-display>
                </ng-container>
                <ng-container *ngSwitchCase="'terminals'">
                  <app-value-display [value]="location?.numOfTerminals"></app-value-display>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  {{location[col.field]}}
                </ng-container>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="footer">
            <tr>
              <td [attr.colspan]="participatingLocationsCols.length" class="text-center w-100">
                <div class="text-center" *ngIf="participatingLocationsTotalRecords != null"> {{participatingLocationsTotalRecords}} results </div>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td [attr.colspan]="participatingLocationsCols.length" class="text-center w-100">
                No locations found.
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </p-tabPanel>
  </p-tabView>
</div>

<p-dialog modal="modal" header="Load New Funds" [draggable]="false" [resizable]="false" [(visible)]="showFundsForm" [dismissableMask]="true"  [style]="{width: '800px'}">
  <form [formGroup]="loadFundsForm" class="p-1" (ngSubmit)="handleFundsUpload()" (keydown.enter)="$event.preventDefault()" novalidate >
    <div class="row">
      <div class="form-group col-12">
        <label class="text-uppercase" for="amount" >Amount</label>
        <input type="text" class="form-control" id="amount" formControlName="amount" aria-describedby="amountHelp">
        <small  class="form-text text-muted" id="amountHelp">The amount deposited to program pool.</small>
      </div>
      <div class="form-group col-12">
        <label class="text-uppercase" for="note" >Note</label>
        <textarea type="text" class="form-control" id="note" formControlName="note" aria-describedby="noteHelp"></textarea>
        <small  class="form-text text-muted" id="noteHelp">Any information describing deposit.</small>
      </div>
    </div>
    <div class="row">
      <div class="text-left col">
        <hr>
        <button type="submit" class="btn btn-primary btn-custom" [disabled]="!loadFundsForm.valid || !loadFundsForm.dirty" name="saveButton">
          <i class="fa fa-spinner fa-spin" *ngIf="loadFundsLoading"></i>
          <span *ngIf="!loadFundsLoading">Save</span>
        </button>
      </div>
    </div>
  </form>
</p-dialog>

<p-dialog modal="modal" header="Load History" [draggable]="false" [resizable]="false" [(visible)]="showFundsTable" [dismissableMask]="true"  [style]="{width: '50%'}">
  <div class="row">
    <p-table [value]="fundingLogs" [columns]="fundingLogsCols" [rows]="25" [paginator]="true" [pageLinks]="8"
             [autoLayout]="true" class="col-12" sortField="created" [sortOrder]="-1" [loading]="loading" [totalRecords]="fundingLogsTotalRecords"
             [lazy]="true" (onLazyLoad)="loadDataLazy($event)" styleClass="p-datatable-gridlines" #fundingLogsDt>
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let col of fundingLogsCols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-log>
        <tr>
          <td *ngFor="let col of fundingLogsCols" [ngSwitch]="col.field" >
            <ng-container *ngSwitchCase="'amount'">
              {{ (log?.amount | currency:'USD') }}
            </ng-container>
            <ng-container *ngSwitchCase="'created'">
              <app-value-display [value]="log?.created" type="timestamp"></app-value-display>
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{log[col.field]}}
            </ng-container>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="fundingLogsCols.length" class="text-center w-100">
            No logs found.
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-dialog>

<p-dialog modal="modal" header="Exporting Details..." [draggable]="false" [resizable]="false" [(visible)]="showExportLoading" [dismissableMask]="false"  [style]="{width: '400px'}">
  <div class="text-center col">
    <p-progressSpinner></p-progressSpinner>
  </div>
</p-dialog>

<p-dialog modal="modal" header="Create Merchant" [draggable]="false" [resizable]="false" [(visible)]="showNewMidForm" [dismissableMask]="true"  [style]="{width: '900px'}">
  <app-form-offers-merchant (updated)="createMidComplete($event)"></app-form-offers-merchant>
</p-dialog>
