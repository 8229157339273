<div class="row" *ngIf="loading || loadingInvitations || loadingRoles || loadingGroups || loadingUsers">
  <div class="col"></div>
  <div class="text-center col">
    <p-progressSpinner></p-progressSpinner>
  </div>
  <div class="col"></div>
</div>
<div class="row" *ngIf="!loading && !loadingInvitations && !loadingRoles && !loadingGroups && !loadingUsers">
  <div class="col-12">
    <p-tabView class="tabview">
      <p-tabPanel header="Users">
        <div class="row">
          <div class="col-12">
            <p-table [value]="users" [columns]="usersCols" [rows]="25" [paginator]="false" [autoLayout]="true" sortField="createdAt" [sortOrder]="-1"
                     [globalFilterFields]="['isDeleted','scope.groups']">
              <ng-template pTemplate="caption">
                <div class="d-flex flex-row">
                  <div class="col-4">
                    <p-columnFilter field="isDeleted" matchMode="equals" [showMenu]="false">
                      <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                        <p-dropdown class="p-fluid" styleClass="form-control p-0" [ngModel]="value" [options]="status" [showClear]="true"
                                    placeholder="Status" (onChange)="filter($event.value)"
                                    optionLabel="label" optionValue="value"></p-dropdown>
                      </ng-template>
                    </p-columnFilter>
                  </div>
                  <div class="col-4">
                    <p-columnFilter field="scope.groups" matchMode="arrayHas" [showMenu]="false">
                      <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                        <p-multiSelect class="p-fluid" styleClass="form-control p-0"
                                       [ngModel]="value" [options]="groups" placeholder="Groups"
                                       (onChange)="filter($event.value)"
                                       optionLabel="name" optionValue="code"></p-multiSelect>
                      </ng-template>
                    </p-columnFilter>
                  </div>
                  <div class="col-4 d-flex align-items-center justify-content-end">
                    <button class="btn btn-primary" (click)="showUserInviteForm = true">Invite New User</button>
                  </div>
                </div>
              </ng-template>
              <ng-template pTemplate="header">
                <tr>
                  <th *ngFor="let col of usersCols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-user>
                <tr>
                  <td *ngFor="let col of usersCols" [ngSwitch]="col.field" >
                    <ng-container *ngSwitchCase="'email'">
                      {{user?.email}}
                    </ng-container>
                    <ng-container *ngSwitchCase="'group'">
                      {{getGroupNames(user?.scope.groups)}}
                    </ng-container>
                    <ng-container *ngSwitchCase="'created'">
                      {{user?.createdAt | date:'medium'}}
                    </ng-container>
                    <ng-container *ngSwitchCase="'status'">
                      {{!user?.isDeleted ? 'Active' : 'Deleted'}}
                    </ng-container>
                    <ng-container *ngSwitchCase="'manager'">
                      {{user?.isManager}}
                    </ng-container>
                    <ng-container *ngSwitchCase="'action'">
                      <ng-container *ngIf="!user?.isDeleted">
                        <button pButton pRipple type="button" icon="pi pi-user-edit" class="p-button-rounded p-button-text" pTooltip="Edit" (click)="showEditUser(user)"></button>
                        <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" pTooltip="Delete" (click)="deleteUser(user?.id)"></button>
                      </ng-container>
                    </ng-container>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td [attr.colspan]="usersCols.length" class="text-center w-100">
                    No users found.
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="Invitations">
        <div class="row">
          <div class="col-12">
            <p-table [value]="invitations" [columns]="invitationsCols" [rows]="25" [paginator]="false" [autoLayout]="true" sortField="createdAt" [sortOrder]="-1" >
              <ng-template pTemplate="header">
                <tr>
                  <th *ngFor="let col of invitationsCols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-invitation>
                <tr>
                  <td *ngFor="let col of invitationsCols" [ngSwitch]="col.field" >
                    <ng-container *ngSwitchCase="'email'">
                      {{invitation?.email}}
                    </ng-container>
                    <ng-container *ngSwitchCase="'created'">
                      {{invitation?.createdAt | date:'medium'}}
                    </ng-container>
                    <ng-container *ngSwitchCase="'expires'">
                      {{invitation?.expiresAt | date:'medium'}}
                    </ng-container>
                    <ng-container *ngSwitchCase="'action'">
                      <button pButton pRipple type="button" icon="pi pi-send" class="p-button-rounded p-button-text" pTooltip="Resend" (click)="resendInvitation(invitation?.id)"></button>
                      <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger p-button-text" pTooltip="Delete" (click)="deleteInvitation(invitation?.id)"></button>
                    </ng-container>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td [attr.colspan]="invitationsCols.length" class="text-center w-100">
                    No invitations found.
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </p-tabPanel>
    </p-tabView>
  </div>
</div>

<p-dialog modal="modal" header="Invite New User" [draggable]="false" [resizable]="false" [(visible)]="showUserInviteForm" [dismissableMask]="true" [style]="{width: '800px'}" (onHide)="inviteUserForm.reset()">
  <form [formGroup]="inviteUserForm" (ngSubmit)="inviteNewUser()" (keydown.enter)="$event.preventDefault()" novalidate>
    <div class="form-group col-12">
      <label class="text-uppercase m-auto" for="email">Email</label>
      <input autocomplete="off" class="form-control" id="email" type="text" formControlName="email">
    </div>
    <div class="form-group col-12">
        <label class="text-uppercase" for="groups">Groups</label>
        <p-multiSelect appendTo="body" id="groups" [filter]="false"
                       class="p-fluid" styleClass="form-control p-0"
                       formControlName="groups" [options]="groups"
                       optionLabel="name" optionValue="id"></p-multiSelect>
    </div>
    <div class="float-right">
      <button class="mr-1" type="button" pButton icon="pi pi-times" (click)="showUserInviteForm = false; inviteUserForm.reset();" label="Cancel"></button>
      <button [disabled]="validateInviteNewUserForm() || loading" type="submit" pButton icon="pi pi-check" label="Submit"></button>
    </div>
  </form>
</p-dialog>

<p-dialog modal="modal" header="Edit User" [draggable]="false" [resizable]="false" [(visible)]="showUserEditForm" [dismissableMask]="true" [style]="{width: '800px'}" (onHide)="editUserForm.reset()">
  <form [formGroup]="editUserForm" (ngSubmit)="editUser()" (keydown.enter)="$event.preventDefault()" novalidate>
    <div class="form-group col-12">
      <label class="text-uppercase m-auto" for="givenName">Given Name</label>
      <input autocomplete="off" class="form-control" id="givenName" type="text" formControlName="givenName">
    </div>
    <div class="form-group col-12">
      <label class="text-uppercase m-auto" for="familyName">Family Name</label>
      <input autocomplete="off" class="form-control" id="familyName" type="text" formControlName="familyName">
    </div>
    <div class="form-group col-12">
      <label class="text-uppercase my-auto ml-auto mr-2" for="isManager">Is Manager</label>
      <p-checkbox formControlName="isManager" [binary]="true"></p-checkbox>
    </div>
    <div class="form-group col-12">
      <label class="text-uppercase" for="groups">Groups</label>
      <p-multiSelect appendTo="body" id="groups" [filter]="false"
                     class="p-fluid" styleClass="form-control p-0"
                     formControlName="groups" [options]="groups"
                     optionLabel="name" optionValue="code"></p-multiSelect>
    </div>
    <div class="float-right">
      <button class="mr-1" type="button" pButton icon="pi pi-times" (click)="showUserEditForm = false; editUserForm.reset();" label="Cancel"></button>
      <button [disabled]="validateEditUserForm() || loading" type="submit" pButton icon="pi pi-check" label="Submit"></button>
    </div>
  </form>
</p-dialog>
