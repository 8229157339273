import {Component, Input, OnInit} from '@angular/core';
import {Funds, FundsService} from '../../../services/offers/funds.service';
import {AuthService} from "../../../services/auth.service";
import {AuthRoles} from "../../../domain/auth/auth-roles";

@Component({
  selector: 'app-funds-card',
  templateUrl: './funds-card.component.html',
  styleUrls: ['./funds-card.component.css']
})
export class FundsCardComponent implements OnInit {

  @Input()
  consumerId: string;

  formVisible = false;
  loading = false;

  funds: Funds;

  fundDisable = true;

  constructor(private fundsService: FundsService,
              private authService: AuthService) { }

  ngOnInit(): void {
    this.getFunds();
    this.authService.hasRole([AuthRoles.ADMIN, AuthRoles.SUPPORT]).subscribe(result => this.fundDisable = !result);
  }

  getFunds() {
    this.loading = true;
    this.fundsService.getFunds(this.consumerId)
      .subscribe(funds => {
        this.loading = false;
        this.funds = funds;
      }, error => {
        this.loading = false;
        if (error.code === 10040) {
          this.funds = new Funds();
          return;
        } else {
          this.funds = null;
        }
      });
  }

  addFunds(storeCredit: boolean, amountElement: any, memoElement: any, internalNoteElement: any) {
    const amount = amountElement.value;
    if (storeCredit && amount <= 0) {
      return;
    }

    this.fundsService.updateFunds(this.consumerId, amount, memoElement.value, internalNoteElement.value).subscribe(result => {
      this.getFunds();
      amountElement.value = null;
      this.formVisible = false;
    }, error => {
      console.log(error);
    });
  }


}
