// Build information, automatically generated by `ng-info`
const build = {
    version: "2.22.1",
    timestamp: "Mon Nov 25 2024 15:32:31 GMT+0000 (Coordinated Universal Time)",
    message: null,
    git: {
        user: null,
        branch: "HEAD",
        hash: "759009",
        fullHash: "75900958241ef3bed355dfd99dd3700483642055"
    }
};

export default build;