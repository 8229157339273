<div class="container content">
  <app-page-header title='Leads'></app-page-header>
  <p-tabView class="tabview">
    <p-tabPanel header="Leads">
      <form [formGroup]="leadsSearchForm" class="row mb-2" (ngSubmit)="doLeadsSearch()" (keydown.enter)="$event.preventDefault()" novalidate>
        <div class="form-group col-xs-12 col-sm-6 col-md-6">
          <label class="w-100">
            <span class="text-uppercase">Given Name</span>
            <input autocomplete="off" formControlName="givenName" type="text" class="form-control" />
          </label>
        </div>
        <div class="form-group col-xs-12 col-sm-6 col-md-6">
          <label class="w-100">
            <span class="text-uppercase">Family Name</span>
            <input autocomplete="off" formControlName="familyName" type="text" class="form-control" />
          </label>
        </div>
        <div class="form-group col-xs-12 col-sm-6 col-md-6">
          <label class="w-100">
            <span class="text-uppercase">Email</span>
            <input autocomplete="off" formControlName="email" type="email" class="form-control" />
          </label>
        </div>
        <div class="form-group col-xs-12 col-sm-6 col-md-6">
          <label class="w-100">
            <span class="text-uppercase">Phone Number</span>
            <input autocomplete="off" class="form-control" type="tel" formControlName="phone" maxlength="10">
          </label>
        </div>
        <div class="col-12 d-flex justify-content-end">
          <p-button label="Clear" type="submit" class="p-fluid mr-2" styleClass="p-button-secondary p-button-sm" (onClick)="clearLeadsSearchForm()"></p-button>
          <p-button [disabled]="!leadsSearchForm.valid || loadingLeads" label="Search" type="submit" class="p-fluid" styleClass="p-button-sm"></p-button>
        </div>
      </form>
      <p-table [value]="leads" [columns]="leadsCols" [rows]="25" [paginator]="true" [pageLinks]="8" [rowsPerPageOptions]="[25,50,100]"
               [responsive]="true" [loading]="loadingLeads" (onLazyLoad)="loadLeadsLazy($event)"
               [totalRecords]="totalLeadsRecords" [lazy]="true" [autoLayout]="true" class="w-100" #leadsDt>
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let col of leadsCols" [pSortableColumn]="col.sort ? col.field : null">{{col.header}} <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-lead>
          <tr>
            <td *ngFor="let col of leadsCols" [ngSwitch]="col.field" >
              <ng-container *ngSwitchCase="'name'">
                <app-value-display [value]="lead.givenName + ' ' + lead.familyName"></app-value-display>
              </ng-container>
              <ng-container *ngSwitchCase="'brands'">
                <app-value-display [value]="lead.optInOrganizations?.length"></app-value-display>
              </ng-container>
<!--              <ng-container *ngSwitchCase="'actions'">-->
<!--                <button pButton pRipple type="button" icon="pi pi-ellipsis-v" class="p-button-rounded p-button-text" (click)="lMenu.toggle($event)"></button>-->
<!--                <p-menu #lMenu [popup]="true" [model]="getLeadsOptions(lead)" appendTo="body"></p-menu>-->
<!--              </ng-container>-->
              <ng-container *ngSwitchDefault>
                <app-value-display [value]="lead[col.field]"></app-value-display>
              </ng-container>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr *ngIf="totalLeadsRecords != null && totalLeadsRecords > 0">
            <td [attr.colspan]="leadsCols.length">
              <div class="text-center">{{ totalLeadsRecords }} results</div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td [attr.colspan]="leadsCols.length" class="text-center w-100">
              No leads found.
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-tabPanel>
    <p-tabPanel header="Conversions">
      <form [formGroup]="convertedLeadsSearchForm" class="row mb-2" (ngSubmit)="doConvertedLeadsSearch()" (keydown.enter)="$event.preventDefault()" novalidate>
        <div class="form-group col-xs-12 col-sm-12 col-md-4">
          <label class="text-uppercase" for="dateRange">Date</label>
          <p-dropdown id="dateRange" class="p-fluid" styleClass="form-control p-0"
                      [(ngModel)]="dateRangeSelected" [ngModelOptions]="{standalone: true}" [options]="dateRanges"
                      (onChange)="onDateRangeSelected($event)"
                      [autoDisplayFirst]="false"></p-dropdown>
        </div>
        <div *ngIf="dateRangeSelected === 'custom range'" class="w-100 d-flex">
          <div class="form-group col-xs-12 col-sm-6 col-md-6">
            <label class="w-100">
              <span class="text-uppercase">Date From</span>
              <p-calendar class="pull-right p-fluid" inputStyleClass="form-control" formControlName="dateFrom"
                          showTime="true"></p-calendar>
            </label>
          </div>
          <div class="form-group col-xs-12 col-sm-6 col-md-6">
            <label class="w-100">
              <span class="text-uppercase">Date To</span>
              <p-calendar class="pull-right p-fluid" inputStyleClass="form-control" formControlName="dateTo"
                          showTime="true"></p-calendar>
            </label>
          </div>
        </div>
        <div class="col-12 d-flex justify-content-end">
          <p-button label="Clear" type="submit" class="p-fluid mr-2" styleClass="p-button-secondary p-button-sm" (onClick)="clearConvertedLeadsSearchForm()"></p-button>
          <p-button [disabled]="!convertedLeadsSearchForm.valid || loadingConvertedLeads" label="Search" type="submit" class="p-fluid" styleClass="p-button-sm"></p-button>
        </div>
      </form>
      <div class="d-flex" style="gap: 1rem">
        <app-metric-card icon="pi pi-check-circle" value="{{leadStats?.newLeadsCount}}">
          <div text>
            New Leads
          </div>
        </app-metric-card>
        <app-metric-card icon="pi pi-check-circle" value="{{leadStats?.newConsumersCount}}"
                         tooltip="Leads that have converted to Consumers">
          <div text>
            New Consumers
            <button pButton type="button" label="View List" class="p-button-link p-0" style="font-size: .75rem;" (click)="showConvertedLeadsTable = true"></button>
          </div>
        </app-metric-card>
        <app-metric-card icon="pi pi-check-circle" value="{{leadStats?.averageLeadToConsumerDays}}"
                         tooltip="Average amount of time (in days) it takes for lead conversion">
          <div text>
            Days to convert
          </div>
        </app-metric-card>
      </div>
    </p-tabPanel>
  </p-tabView>
  <div class="row">
  </div>
</div>

<p-dialog modal="modal" header="Leads To Consumers" [draggable]="false" [resizable]="false"
          [(visible)]="showConvertedLeadsTable" [dismissableMask]="true">
  <p-table [value]="convertedLeads" [columns]="convertedLeadsCols" [rows]="25" [paginator]="true" [pageLinks]="8"
           [rowsPerPageOptions]="[25,50,100]"
           [responsive]="true" [loading]="loadingLeads" (onLazyLoad)="loadConvertedLeadsLazy($event)"
           [totalRecords]="totalConvertedLeadsRecords" [lazy]="true" [autoLayout]="true" class="w-100"
           #convertedLeadsDt>
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let col of convertedLeadsCols" [pSortableColumn]="col.sort ? col.field : null">{{ col.header }}
          <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-convertedLead>
      <tr>
        <td *ngFor="let col of convertedLeadsCols" [ngSwitch]="col.field">
          <ng-container *ngSwitchCase="'consumerId'">
            <a class="d-none d-lg-inline-block" routerLink="/user/{{convertedLead.consumerId}}" routerLinkActive="active">{{convertedLead.consumerId.split('-')[0]}}...</a>
          </ng-container>
          <ng-container *ngSwitchCase="'name'">
            <app-value-display [value]="convertedLead.givenName + ' ' + convertedLead.familyName"></app-value-display>
          </ng-container>
          <ng-container *ngSwitchCase="'convertedAt'">
            <app-value-display [value]="convertedLead.convertedAt" type="timestamp"></app-value-display>
          </ng-container>
          <ng-container *ngSwitchCase="'actions'">
            <button pButton pRipple type="button" class="p-button-text" label="Details"
                    (click)="selectedConvertedLead = convertedLead"></button>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <app-value-display [value]="convertedLead[col.field]"></app-value-display>
          </ng-container>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="footer">
      <tr *ngIf="totalConvertedLeadsRecords != null && totalConvertedLeadsRecords > 0">
        <td [attr.colspan]="convertedLeadsCols.length">
          <div class="text-center">{{ totalConvertedLeadsRecords }} results</div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="convertedLeadsCols.length" class="text-center w-100">
          No lead conversions found.
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>

<p-dialog modal="modal" header="Details" [draggable]="false" [resizable]="false"
          [(visible)]="selectedConvertedLead" [dismissableMask]="true" (onHide)="selectedConvertedLead = null">
  <pre>{{selectedConvertedLead | json}}</pre>
</p-dialog>
