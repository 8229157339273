import {Component, Input, OnInit} from '@angular/core';
import {WalletService} from '../../../services/wallet.service';
import {TopupVelocity} from '../../../domain/wallet/topup-velocity';
import {ErrorDisplayService} from "../../../services/error-display.service";
import {AuthService} from "../../../services/auth.service";
import {AuthRoles} from "../../../domain/auth/auth-roles";

@Component({
  selector: 'app-wallet-topup-card',
  templateUrl: './wallet-topup-card.component.html',
  styleUrls: ['./wallet-topup-card.component.css']
})
export class WalletTopupCardComponent implements OnInit {


  @Input()
  consumerId: string;

  Object = Object;
  loading = false;
  limitsFormOpen = false;
  errorMessage: string;
  topupVelocity: TopupVelocity;
  data = {};

  failedGet = false;

  limitDisable = true;

  constructor(private walletService: WalletService,
              private errorDisplayService: ErrorDisplayService,
              private authService: AuthService) { }

  ngOnInit(): void {
    this.getTopups();
    this.authService.hasRole([AuthRoles.ADMIN, AuthRoles.SUPPORT]).subscribe(result => this.limitDisable = !result);
  }

  getTopupLimits() {
    this.walletService.getAccount(this.consumerId).subscribe(result => {
      this.data['Day']['limit'] = result.topUpDailyLimit;
      this.data['Week']['limit'] = result.topUpWeeklyLimit;
      this.data['Month']['limit'] = result.topUpMonthlyLimit;
      this.loading = false;
    }, error => {
      this.failedGet = this.errorDisplayService.displayErrorResponse('Wallet Top Up Limits', error);
      this.loading = false
    })
  }

  getTopups() {
    this.loading = true;
    this.walletService.getTopupVelocity(this.consumerId).subscribe(result => {
      this.topupVelocity = result;
      this.data['Day'] = {'count': result.lastDay};
      this.data['Week'] = {'count': result.lastWeek};
      this.data['Month'] = {'count': result.lastMonth};
      this.getTopupLimits();
    }, error => {
      this.failedGet = this.errorDisplayService.displayErrorResponse('Wallet Top Up Velocity', error);
      this.loading = false;
    });
  }

  toggleLimitsForm() {
    if (this.limitsFormOpen) {
      //save
      this.loading = true;
      this.walletService.setTopupLimits(this.consumerId, this.data['Day']['limit'], this.data['Week']['limit'], this.data['Month']['limit']).subscribe(result => {
          this.getTopups();
          this.loading = false;
        }, error => {
        this.errorDisplayService.displayErrorResponse('Wallet Set Top Up Limits', error);
        this.loading = false;
        }
      )
    }
    this.limitsFormOpen = !this.limitsFormOpen;

  }
}
