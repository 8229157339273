<ng-container  *ngIf="!userUuid">
  <form [formGroup]="form" class="row py-2" (ngSubmit)="doSearch()" novalidate>

<!--    <div class="form-group col-xs-12 col-sm-6 col-md-6">-->
<!--      <label class="text-uppercase" for="firstName">First Name</label>-->
<!--      <input class="form-control" id="firstName" type="text" formControlName="firstName">-->
<!--    </div>-->

<!--    <div class="form-group col-xs-12 col-sm-6 col-md-6">-->
<!--      <label class="text-uppercase" for="lastName">Last Name</label>-->
<!--      <input class="form-control" id="lastName" type="text" formControlName="lastName">-->
<!--    </div>-->

<!--    <div class="form-group col-xs-12 col-sm-6 col-md-6">-->
<!--      <label class="text-uppercase" for="email">Email SherlockAddress</label>-->
<!--      <input class="form-control" id="email" type="text" formControlName="email">-->
<!--    </div>-->

<!--    <div class="form-group col-xs-12 col-sm-6 col-md-6">-->
<!--      <label class="text-uppercase" for="zip">Zip</label>-->
<!--      <input class="form-control" id="zip" type="text" formControlName="zip">-->
<!--    </div>-->

    <div class="form-group col-xs-12 col-sm-6 col-md-6">
      <label class="text-uppercase" [ngClass]="{'text-muted':form.controls['kycMethods'].value.length == 0}" >Methods</label>
      <div class="form-control mb-3 h-auto">
        <ng-container  *ngFor="let kycMethod of kycMethods">
          <p-checkbox [ngClass]="{'text-muted':form.controls['kycMethods'].value.length == 0}"  name="sources" label="{{kycMethod.label}}" value="{{kycMethod.value}}" [formControl]="form.controls['kycMethods']">{{kycMethod.label}}</p-checkbox><br>
        </ng-container>
      </div>
    </div>

    <div class="form-group col-xs-12 col-sm-6 col-md-6">
      <label class="text-uppercase">Other</label>
      <div class="form-control h-auto">
        <p-triStateCheckbox formControlName="valid" label="Valid"></p-triStateCheckbox><br/>
      </div>
    </div>


    <div class="col-12 text-right">
      <button class="btn btn-secondary btn-sm mr-2" (click)="clearForm()">Clear</button>
      <button type="submit" class="btn btn-primary btn-sm" [disabled]="!form.valid || loading" name="searchButton" >
        <span>Search</span>
      </button>
    </div>
  </form>
</ng-container>
<div class="row">
  <p-table (sortFunction)="customSort($event)" [customSort]="true" [value]="sherlockResults" [columns]="cols" [rows]="10" [paginator]="true"
           [pageLinks]="8" [rowsPerPageOptions]="[10,25]" [responsive]="true"
           [loading]="loading"  (onLazyLoad)="loadKycAttemptsLazy($event)"  [totalRecords]="totalRecords" [lazy]="true" [autoLayout]="true"
           class="col-12"  sortField="created" [sortOrder]="-1" styleClass="p-datatable-gridlines" #dt>
   <ng-template pTemplate="header">
     <tr>
       <th *ngFor="let col of cols" [ngClass]="{'d-none d-md-table-cell':col.field == 'created', 'd-none':col.field == 'user' && userUuid}" >
         {{col.header}}
       </th>
     </tr>
   </ng-template>
   <ng-template pTemplate="body" let-rowData let-columns="columns"  >
     <tr>
       <td *ngFor="let col of columns" [ngSwitch]="col.field" [ngClass]="{'d-none d-md-table-cell':col.field == 'kycTimestamp', 'd-none':col.field == 'user' && userUuid}" >
         <div *ngSwitchCase="'kycTimestamp'">
           <span class="d-none d-md-block">{{rowData[col.field] | date:'medium'}}</span>
           <span class="d-block d-md-none">{{rowData[col.field] | date:'M/d/yy'}}</span>
         </div>
         <div *ngSwitchCase="'kycMethod'">
           {{rowData['kycMethod']}}
         </div>
         <div *ngSwitchCase="'user'">
           <a class="d-none d-lg-block" routerLink="/user/{{rowData['user']}}" routerLinkActive="active">{{ rowData['user'] }}</a>
           <a class="d-block d-lg-none" routerLink="/user/{{rowData['user']}}" routerLinkActive="active">View</a>
         </div>
         <div *ngSwitchCase="'kycCompleted'">

           <i *ngIf="rowData['kycCompleted']" style="color:green" class="fa fa-check-circle-o mr-1"></i>
           <i *ngIf="!rowData['kycCompleted']" style="color:red" class="fa fa-times-circle-o mr-1"></i>
<!--             <a class="d-none d-lg-block" routerLink="/user/{{rowData['user']}}" routerLinkActive="active">{{ rowData['user'] }}</a>-->
<!--             <a class="d-block d-lg-none" routerLink="/user/{{rowData['user']}}" routerLinkActive="active">View</a>-->
         </div>
<!--           <div *ngSwitchCase="'valid'">-->
<!--             <i *ngIf="rowData['valid']" style="color:green" class="fa fa-check fa-lg"  pTooltip="Verified"></i>-->
<!--             <i *ngIf="!rowData['valid']" style="color:red" class="fa fa-times-circle-o fa-lg"  pTooltip="{{rowData['result']}}"></i>-->
<!--           </div>-->
<!--           <div *ngSwitchCase="'addressVerified'">-->
<!--             <i *ngIf="rowData['addressMatched'] && rowData['addressMatched'].verified" style="color:green" class="fa fa-check fa-lg"  pTooltip="SherlockAddress Verified"></i>-->
<!--             <i *ngIf="rowData['addressMatched'] && !(rowData['addressMatched'].verified)" style="color:red" class="fa fa-times-circle-o fa-lg"  pTooltip="{{rowData['addressMatched'].unverifiedDetail}}"></i>-->
<!--           </div>-->
<!--           <div *ngSwitchCase="'govtScreeningPassed'">-->
<!--             <i *ngIf="rowData['govtScreeningPassed']" style="color:green" class="fa fa-check fa-lg"  pTooltip="OFAC Passed"></i>-->
<!--             <i *ngIf="(rowData['govtScreeningPassed'] == false)" style="color:red" class="fa fa-times-circle-o fa-lg"  pTooltip="OFAC Match Found"></i>-->
<!--           </div>-->
<!--           <div *ngSwitchCase="'shortForm'">-->
<!--             <span *ngIf="rowData[col.field]"  style="color:#265a88">-->
<!--               <span class="d-block d-sm-none d-md-block">Short</span>-->
<!--               <span class="d-none d-sm-block d-md-none">S</span>-->
<!--             </span>-->
<!--             <span *ngIf="!rowData[col.field]" style="color:mediumseagreen">-->
<!--               <span class="d-none d-md-block">Long</span>-->
<!--               <span class="d-block d-md-none">L</span>-->
<!--             </span>-->
<!--           </div>-->
         <div *ngSwitchCase="'view'">
           <button type="text" pButton label="View" (click)="showDetailsFor(rowData)" [disabled]="rowData['removed']"></button>
         </div>
         <div *ngSwitchCase="'remove'">
           <button [disabled]="deleteDisable" *ngIf="!rowData['kycRecord'] && !rowData['removed']" type="text" class="ui-button-danger" pButton label="Delete" (click)="remove(rowData)" ></button>
           <span *ngIf="rowData['kycRecord']" class="text-muted d-none d-md-block">KYC Record</span>
           <span *ngIf="rowData['removed']" class="text-muted d-none d-md-block">Removed</span>
         </div>
         <div *ngSwitchDefault>
           {{rowData[col.field]}}
         </div>
       </td>
     </tr>
   </ng-template>
    <ng-template pTemplate="footer">
      <tr *ngIf="totalRecords != null && totalRecords > 0">
        <td [attr.colspan]="cols.length">
          <div class="text-center">{{ totalRecords }} results</div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [attr.colspan]="cols.length" class="text-center w-100">
          No attempts found.
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
