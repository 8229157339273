import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Table} from 'primeng/table';
import {HapiService} from '../../services/hapi.service';
import {ConsumersApiService} from '../../services/consumers-api.service';
import {PageRequest} from '../../domain/common/paging';
import {FilterField} from '../../domain/common/search/filter-field';
import {LazyLoadEvent} from 'primeng/api';
import {OffersUser} from '../../domain/offers/user';
import {Panel} from 'primeng/panel';
import {AuthTransaction} from '../../domain/hapi/transaction/auth-transaction';

@Component({
  selector: 'app-closed-loop-transactions-table',
  templateUrl: './closed-loop-transactions-table.component.html',
  styleUrls: ['./closed-loop-transactions-table.component.css']
})
export class ClosedLoopTransactionsTableComponent implements OnInit {

  @Input()
  consumerId: string;

  loading = true;
  form: FormGroup;

  messageTypes: string[] = ['1100', '1120', '1200', '1220', '1221', '1420', '1421', '1422'];
  transactionTypes: string[] = ['0', '20'];
  cardBins: string[] = [
    'ov_corporate_incentive',
    'ov_tokenized_corporate_incentive',
    'reloadable_android',
    'reloadable_emv_ios',
    'ov_gpr'
  ];

  totalRecords: number;
  transactions: AuthTransaction[];
  @ViewChild('dt') datatable: Table;
  cols: any[];

  selectedTransaction: any;
  selectedUser: string;
  detailVisible = false;

  constructor(private hapiService: HapiService,
              private fb: FormBuilder) {

    this.initializeForm();

    this.cols = [
      { field: 'created', header: 'Timestamp', sort: true },
      { field: 'user', header: 'User', sort: false },
      { field: 'merchantName', header: 'Merchant', sort: false },
      { field: 'city', header: 'City', sort: false },
      { field: 'state', header: 'ST', sort: false },
      { field: 'messageType', header: 'Type', sort: false },
      { field: 'authCode', header: 'Auth Code', sort: false },
      { field: 'transactionAmount', header: 'Amount', sort: false },
      { field: 'amountApproved', header: 'Approved', sort: false },
      { field: 'callbackRequestSent', header: 'CallbackRequestSent', sort: false },
      { field: 'callbackResponseReceived', header: 'CallbackResponseReceived', sort: false },
      { field: 'callbackResponseBody', header: 'CallbackResponseBody', sort: false },
      { field: 'action', header: '', sort: false }
    ];
  }

  ngOnInit() {}

  initializeForm() {
    this.form = this.fb.group({
      startDateTime: [null],
      endDateTime: [null],
      minAmount: [null],
      maxAmount: [null],
      userUuid: [''],
      merchant: [''],
      deviceUuid: [''],
      merchantFilterExact: [false],
      city: [''],
      cityFilterExact: [false],
      state: [''],
      stateFilterExact: [false],
      authCode: [''],
      responseSuccess: [null],
      tokenized: [null],
      dcvvVerified: [null],
      cardBins: [[]],
      messageTypes: [[]],
      transactionTypes: [[]],
    })
  }

  clearForm() {
    this.form.reset()
  }

  doSearch() {
    this.datatable.first = 0;
    this.loadTransactionsLazy(this.datatable.createLazyLoadMetadata());
    this.form.markAsPristine();
  }

  getTransactions(pageRequest: PageRequest, filterFields: FilterField[]) {
    this.loading = true;
    this.hapiService.getClosedLoopTransactions(pageRequest, filterFields).subscribe(data => {
      this.loading = false;
      this.transactions = data.content;
      this.totalRecords = data.totalElements;
    }, error => {
      this.loading = false;
    })
  }

  loadTransactionsLazy(event: LazyLoadEvent) {
    const filters: FilterField[] = this.getFilterFields();

    filters.push({
      parameter: 'sort',
      value: event.sortField + ',' + (event.sortOrder === 1 ? 'asc' : 'desc')
    });

    const page: number = event.first / event.rows;
    const pageRequest: PageRequest = {page, size: event.rows};
    this.getTransactions(pageRequest, filters);
  }

  getFilterFields(): FilterField[] {
    const filterFields: FilterField[] = [];
    if (this.consumerId) {
      filterFields.push({parameter: 'userUuid', value: this.consumerId});
      filterFields.push({parameter: 'tenantUserUuid', value: this.consumerId});
      return filterFields;
    }

    if (this.form.controls.startDateTime.value) {
      filterFields.push({parameter: 'startDate', value: this.form.controls.startDateTime.value.getTime()});
    }
    if (this.form.controls.endDateTime.value) {
      filterFields.push({parameter: 'endDate', value: this.form.controls.endDateTime.value.getTime()});
    }
    if (this.form.controls.minAmount.value) {
      filterFields.push({parameter: 'minAmount', value: this.form.controls.minAmount.value});
    }
    if (this.form.controls.maxAmount.value) {
      filterFields.push({parameter: 'maxAmount', value: this.form.controls.maxAmount.value});
    }
    if (this.form.controls.userUuid.value) {
      filterFields.push({parameter: 'userUuid', value: this.form.controls.userUuid.value});
      filterFields.push({parameter: 'tenantUserUuid', value: this.form.controls.userUuid.value});
    }
    if (this.form.controls.deviceUuid.value) {
      filterFields.push({parameter: 'device', value: this.form.controls.deviceUuid.value});
    }
    if (this.form.controls.authCode.value) {
      filterFields.push({parameter: 'authCode', value: this.form.controls.authCode.value});
    }
    if (this.form.controls.dcvvVerified.value != null) {
      filterFields.push({parameter: 'dcvvVerified', value: this.form.controls.dcvvVerified.value});
    }
    if (this.form.controls.responseSuccess.value != null) {
      filterFields.push({parameter: 'success', value: this.form.controls.responseSuccess.value});
    }
    if (this.form.controls.tokenized.value != null) {
      filterFields.push({parameter: 'tokenized', value: this.form.controls.tokenized.value});
    }
    if (this.form.controls.cardBins.value) {
      const cardBinsSelected: string[] = this.form.controls.cardBins.value;
      if (cardBinsSelected.length > 0) {
        filterFields.push({parameter: 'cardBins', value: cardBinsSelected.join(',').toLowerCase()});
      }
    }
    if (this.form.controls.transactionTypes.value) {
      const typesSelected: string[] = this.form.controls.transactionTypes.value;
      if (typesSelected.length > 0) {
        filterFields.push({parameter: 'transactionTypes', value: typesSelected.join(',').toLowerCase()});
      }
    }
    if (this.form.controls.messageTypes.value) {
      const typesSelected: string[] = this.form.controls.messageTypes.value;
      if (typesSelected.length > 0) {
        filterFields.push({parameter: 'messageTypes', value: typesSelected.join(',').toLowerCase()});
      }
    }

    this.addMerchantFilters(filterFields);
    return filterFields;
  }


  showUserPreview(event, user: OffersUser, panel: Panel) {
    this.selectedUser = user.uuid;
    panel.toggle(event);
  }

  toggle(controlName: string) {
    const newVal = !(this.form.controls[controlName].value);
    this.form.controls[controlName].patchValue(newVal);
  }

  selectTransaction(transaction) {
    this.selectedTransaction = transaction;
    this.detailVisible = true;
  }

  addMerchantFilters(filterFields) {
    const merchantFilter: string = this.form.controls.merchant.value;
    const cityFilter: string = this.form.controls.city.value;
    const stateFilter: string = this.form.controls.state.value;

    // CITY
    if (stateFilter != null && stateFilter !== '') {
      const val = this.form.controls.stateFilterExact.value ? stateFilter : '%' + stateFilter + '%';
      filterFields.push(new FilterField('state', val));
    }

    // CITY
    if (cityFilter != null && cityFilter !== '') {
      const val = this.form.controls.cityFilterExact.value ? cityFilter : '%' + cityFilter + '%';
      filterFields.push(new FilterField('city', val));
    }

    // MERCHANT
    if (merchantFilter != null && merchantFilter !== '') {
      const val = this.form.controls.merchantFilterExact.value ? merchantFilter : '%' + merchantFilter + '%';
      filterFields.push(new FilterField('merchantName', val));
    }
  }

  getWildcardOf(length: number) {
    return this.stringOf('_', length);
  }

  stringOf(char: string, length: number) {
    let result = '';
    while (length > 0) {
      result = result + char;
      length -= 1;
    }
    return result;
  }

}
